import { Grid } from "@mui/material";
import { FolderShared, ForwardToInboxOutlined, LinkOutlined, MarkEmailReadOutlined, PermIdentityOutlined, QueryStatsRounded, ReceiptRounded } from '@mui/icons-material';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import BoxTitle from '../../components/Library/Box/BoxTitle';
import ReturnButton from '../../components/Library/Buttons/ReturnButton/ReturnButton';
import useAppSelector from "../../redux/useAppSelector";

const ReglageItemBox: React.FC<any> = (props) => {
  const {
    actualPage,
    navigate,
    text,
    Icon,
  } = props;

  return (
    <Link
      to={`/${navigate}`}
      style={{ textDecoration: "none" }}>
      <span
        style={actualPage === navigate ?
          {
            borderTop: '1px solid #E6EBF0',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 500,
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '18px',
            paddingRight: '20px',
            letterSpacing: '0px',
            color: '#0B243C',
            textAlign: 'left',
          }
          :
          {
            borderTop: '1px solid #E6EBF0',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 500,
            height: '50px',
            paddingLeft: '18px',
            paddingRight: '20px',
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '0px',
            color: '#5BB9C1',
            textAlign: 'left',
          }
        }>
        <span style={{ paddingRight: '16px' }}>{Icon}</span>
        {text}
      </span>
    </Link>
  );
};

const MesReglagesBox: React.FC<any> = (props) => {
  //const formsAccess = useAppSelector(state => state.authorizationsReducer.forms);
  //const chatbotsAccess = useAppSelector(state => state.authorizationsReducer.chatbots);
  const history = useHistory();
  const {
    page
  } = props;

  return (
    <Grid
      container
      direction='column'
      wrap="nowrap"
      display='flex'
      paddingLeft='41px'
      paddingTop='26px'
      paddingRight='41px'
      paddingBottom='60px'
      minWidth='520px'
      style={{
        background: 'rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box',
        borderRadius: '24px',
      }}>
      <Grid item display='flex'>
        <BoxTitle
          first='Mes'
          second='Réglages' />
      </Grid>
      <Grid item display='flex' paddingLeft='15%' paddingRight='15%' paddingTop='43px'>
        <Grid container direction='column' wrap="nowrap" justifyContent='center' display='flex'>
          <Grid item display='grid'>
            <ReglageItemBox actualPage={page} text='Mon compte' navigate='profile' Icon={<PermIdentityOutlined />} />
          </Grid>
          <Grid item display='grid'>
            <ReglageItemBox actualPage={page} text='Messages envoyés' navigate='send_messages' Icon={<ForwardToInboxOutlined />} />
          </Grid>
          <Grid item display='grid'>
            <ReglageItemBox actualPage={page} text='Messages programmés' navigate='scheduled_conseils' Icon={<MarkEmailReadOutlined />} />
          </Grid>
          <Grid item display='grid'>
            <ReglageItemBox actualPage={page} text='Liens personnalisés' navigate='links' Icon={<LinkOutlined />} />
          </Grid>
          <Grid item display='grid'>
            <ReglageItemBox actualPage={page} text='Espace de dépôt' navigate='espace_depot' Icon={<FolderShared />} />
          </Grid>
          <Grid item display='grid'>
            <ReglageItemBox actualPage={page} text='Statistiques conseils' navigate='statistiques_conseils' Icon={<QueryStatsRounded />} />
          </Grid>
          <Grid item display='grid'>
            <ReglageItemBox actualPage={page} text='Factures' navigate='factures' Icon={<ReceiptRounded />} />
          </Grid>
          <Grid item display='grid' justifyContent='center' borderTop='1px solid #E6EBF0' paddingTop='34px'>
            <ReturnButton
              text="Revenir à l'accueil"
              onClick={() => history.push('/')} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MesReglagesBox;