import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import BoxTitle from "../components/Library/Box/BoxTitle";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import useAppSelector from "../redux/useAppSelector";
import {
  GenericLongText,
  GenericTextInput,
  useOpenSnackbar,
} from "./CreateOrEditChatbotLexPage";
import makeAPIRequest from "../utils/makeAPIRequest";
import {
  GenericFromPopup,
  GenericSendButton,
  MoreOrLessButtons,
} from "./ChatbotLexPage";
import { MySimpleCreateButton } from "../components/TutorialsList";
import OrangeCheckBox from "../components/Fields/CheckBox/OrangeCheckBox";
import ImportButton from "../components/Library/Buttons/ImportButton/ImportButton";
import { GenericCard, GenericSelect } from "./QuestionnairePage";
import {
  DeleteRounded,
  DownloadRounded,
  PublishRounded,
  SupervisorAccountRounded,
} from "@mui/icons-material";
import { genericGetFromTimestamp } from "../components/AdminGroups/CreateGroupPopup";
import CancellablePopup from "../components/Library/Popups/CancellablePopup";
import { NewGenericSelect } from "../components/Library/Select/MySelect";
import useQuery from "../utils/hooks/useQuery";

export interface DepotFichierData {
  _id?: string;
  created?: number;
  lastUpdate?: number;
  user_id?: string;
  revisionComment?: string;

  title: string;
  description: string;
  fileName: string;
  fileBase64?: string;
  waitingForRevision: boolean;
}

const getInitialData = (): DepotFichierData => {
  return {
    title: "",
    description: "",
    fileBase64: "",
    fileName: "",
    waitingForRevision: false,
  };
};

const EspaceDepotBox: React.FC<any> = (props) => {
  const snackbarOpenUse = useOpenSnackbar();
  const [myDepots, setMyDepots] = React.useState<DepotFichierData[]>([]);
  const [isDeletePopup, setIsDeletePopup] =
    React.useState<DepotFichierData>(undefined);
  const [someFileDownloading, setSomeFileDownloading] =
    React.useState<boolean>(false);
  const [someFileWaitingRevision, setSomeFileWaitingRevision] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [maxItem, setMaxItem] = React.useState<number>(10);
  const [openDepotFichier, setOpenDepotFichier] =
    React.useState<boolean>(false);
  const [depotFichierLoading, setDepotFichierLoading] =
    React.useState<boolean>(false);
  const [depotFichierData, setDepotFichierData] =
    React.useState<DepotFichierData>(getInitialData());
  const [isDeleteLoading, setIsDeleteLoading] = React.useState<boolean>(false);
  const depotId = useQuery().get("depotId");
  const [filter, setFilter] = React.useState<{
    depotId: string;
    status: "waiting_revision";
  }>({
    depotId: undefined,
    status: undefined,
  });

  React.useEffect(() => {
    if (!depotId) {
      return;
    }
    setFilter((x) => {
      return {
        ...x,
        depotId: depotId,
      };
    });
  }, [depotId]);

  const resetDepot = () => {
    setDepotFichierData(getInitialData());
    setDepotFichierLoading(false);
    setOpenDepotFichier(false);
  };

  const loadAllFiles = () => {
    setIsLoading(true);
    makeAPIRequest("get", "/depot/all", null, "v3")
      .then((res) => {
        setMyDepots(res?.data);
      })
      .catch((err) => {
        snackbarOpenUse.error("Impossible de récupérer vos dépôts", err);
      })
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    loadAllFiles();
  }, []);

  const allFilesFilenameOptions = myDepots.map((x) => {
    return {
      value: x?._id,
      label: x?.fileName,
    };
  });

  const allFilesTitleOptions = myDepots.map((x) => {
    return {
      value: x?._id,
      label: x?.title,
    };
  });

  const statusOptions = [
    {
      label: "En attente de révision",
      value: "waiting_revision",
    },
  ];

  const filteredDepots = myDepots.filter((x) => {
    if (filter?.depotId) {
      if (x?._id != filter.depotId) {
        return false;
      }
    }
    if (filter?.status) {
      if (filter.status === "waiting_revision") {
        if (x?.waitingForRevision === false) {
          return false;
        }
      }
    }
    return true;
  });
  return (
    <>
      {isDeletePopup && (
        <CancellablePopup
          firstTitle="Mes"
          isLoading={isDeleteLoading}
          secondTitle="Fichiers"
          messages={[
            `Voulez-vous vraiment supprimer le fichier '${
              isDeletePopup?.fileName ? isDeletePopup?.fileName : "Sans titre"
            }' ?`,
            "La suppression est définitive, aucun retour en arrière n'est possible.",
          ]}
          confirmButtonText="Supprimer"
          cancelButtonText="Annuler"
          onCancel={() => {
            setIsDeletePopup(undefined);
            setIsDeleteLoading(false);
          }}
          onConfirm={() => {
            setIsDeleteLoading(true);
            makeAPIRequest("delete", `/depot/${isDeletePopup?._id}`, null, "v3")
              .then((res) => {
                setIsDeletePopup(undefined);
                snackbarOpenUse.success("Le fichier a bien été supprimé.", res);
                loadAllFiles();
              })
              .catch((err) => {
                snackbarOpenUse.error(
                  "Impossible de supprimer ce fichier.",
                  err
                );
              })
              .finally(() => setIsDeleteLoading(false));
          }}
        />
      )}
      {openDepotFichier && (
        <GenericFromPopup
          onClose={() => setOpenDepotFichier(false)}
          title={{
            leftPart: "Déposer",
            rightPart: "mon fichier",
          }}
          xs={6}
          addCloseSecurity={true}
        >
          <Grid container direction="column" padding="20px" gap="10px">
            <Grid item display="grid">
              <GenericTextInput
                placeholder={
                  depotFichierData?.fileName
                    ? depotFichierData.fileName
                    : "Sans titre"
                }
                fieldTitle="Titre du dépôt"
                isMandatory={false}
                id="title-depot-id"
                value={depotFichierData?.title}
                onChange={(e) => {
                  setDepotFichierData((x) => {
                    return {
                      ...x,
                      title: e?.target?.value ? e.target.value : "",
                    };
                  });
                }}
              />
            </Grid>
            <Grid item display="grid">
              <GenericLongText
                placeholder={"Aucune description"}
                fieldTitle="Description"
                isMandatory={false}
                id="description-depot-id"
                maxLength={5000}
                value={depotFichierData?.description}
                onChange={(e) => {
                  setDepotFichierData((x) => {
                    return { ...x, description: e ? e : "" };
                  });
                }}
              />
            </Grid>

            <Grid item display="grid" paddingTop="10px" paddingBottom="10px">
              <ImportButton
                isAfterImport={depotFichierData?.fileBase64 != ""}
                fileNameAfterImport={depotFichierData?.fileName}
                onCloseAfterImport={() =>
                  setDepotFichierData((x) => {
                    return {
                      ...x,
                      fileBase64: "",
                      fileName: "",
                    };
                  })
                }
                label={"Joindre un fichier"}
                onChange={(e) => {
                  const fileReader = new FileReader();
                  const file = e.target.files?.[0];

                  if (file) {
                    if (file.size >= 20 * 1024 * 1024) {
                      snackbarOpenUse.error(
                        "Le fichier est trop lourd, il ne doit pas dépasser 20MB."
                      );
                    } else {
                      fileReader.readAsDataURL(file);
                      fileReader.onload = (event) => {
                        const base64Data = event.target?.result as string;

                        setDepotFichierData((prevData) => ({
                          ...prevData,
                          fileBase64: base64Data,
                          fileName: file.name,
                        }));
                      };
                    }
                  }
                }}
                acceptedFormat={[
                  ".xls",
                  ".xlsx",
                  ".csv",
                  ".txt",
                  ".docx",
                  ".doc",
                ]}
              >
                <></>
              </ImportButton>
            </Grid>
            <Grid item display="grid">
              <OrangeCheckBox
                value={depotFichierData?.waitingForRevision ? true : false}
                text="Envoyer une demande de révision à un administrateur"
                onClick={() => {
                  setDepotFichierData((x) => {
                    return {
                      ...x,
                      waitingForRevision: x?.waitingForRevision ? false : true,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item display="grid">
              <GenericSendButton
                errorMessages={{
                  noFile: "Aucun fichier n'a été déposé.",
                }}
                errorSelector={{
                  noFile: "hide",
                }}
                invalidConditions={{
                  noFile:
                    !depotFichierData?.fileName ||
                    !depotFichierData?.fileBase64,
                }}
                onSendClick={() => {
                  setDepotFichierLoading(true);
                  makeAPIRequest(
                    "post",
                    "/depot/create",
                    depotFichierData,
                    "v3"
                  )
                    .then((res) => {
                      resetDepot();
                      loadAllFiles();
                      snackbarOpenUse.success(
                        "Le fichier a bien été déposé.",
                        res
                      );
                    })
                    .catch((err) => {
                      snackbarOpenUse.error(
                        "Impossible de déposer ce fichier.",
                        err
                      );
                    })
                    .finally(() => setDepotFichierLoading(false));
                }}
                isLoading={depotFichierLoading}
                textButton={"Valider"}
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      <Grid
        container
        direction="column"
        wrap="nowrap"
        display="flex"
        paddingLeft="41px"
        paddingTop="26px"
        paddingRight="41px"
        paddingBottom="60px"
        maxWidth="1360px"
        minWidth="600px"
        style={{
          background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
          borderRadius: "24px",
        }}
      >
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="space-between"
            columnSpacing="20px"
            rowSpacing="5px"
          >
            <Grid item display="grid">
              <BoxTitle first="Mes" second="fichiers" />
            </Grid>
            <Grid item display="grid">
              <MySimpleCreateButton
                text="Déposer un fichier"
                onClick={() => {
                  setOpenDepotFichier(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing="10px"
            paddingTop="20px"
          >
            <Grid item display="grid">
              <span>Filtrer par :</span>
            </Grid>
            <Grid item display="grid">
              <NewGenericSelect
                fixedWidth="200px"
                fieldTitle={""}
                options={allFilesTitleOptions}
                value={allFilesTitleOptions?.find(
                  (x) => x?.value == filter?.depotId
                )}
                maxMenuHeight={"400px"}
                id={"filter-file-title-input"}
                isClearable={true}
                onChange={(e: any) => {
                  setFilter((x) => {
                    return {
                      ...x,
                      depotId: e?.value ? e.value : undefined,
                    };
                  });
                }}
                placeholder="Titre du dépôt"
                isMandatory={false}
              />
            </Grid>
            <Grid item display="grid">
              <NewGenericSelect
                fixedWidth="200px"
                fieldTitle={""}
                options={allFilesFilenameOptions}
                value={allFilesFilenameOptions?.find(
                  (x) => x?.value == filter?.depotId
                )}
                maxMenuHeight={"400px"}
                id={"filter-file-name-input"}
                isClearable={true}
                onChange={(e: any) => {
                  setFilter((x) => {
                    return {
                      ...x,
                      depotId: e?.value ? e.value : undefined,
                    };
                  });
                }}
                placeholder="Nom du fichier"
                isMandatory={false}
              />
            </Grid>
            <Grid item display="grid">
              <NewGenericSelect
                fixedWidth="150px"
                fieldTitle={""}
                options={statusOptions}
                value={statusOptions?.find((x) => x?.value == filter?.status)}
                maxMenuHeight={"400px"}
                id={"filter-status-input"}
                isClearable={true}
                onChange={(e: any) => {
                  setFilter((x) => {
                    return { ...x, status: e?.value ? e.value : undefined };
                  });
                }}
                placeholder="Status"
                isMandatory={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          display="grid"
          paddingTop="40px"
          paddingLeft="8%"
          paddingRight="8%"
        >
          <Grid container direction="column" wrap="nowrap" gap="10px">
            <Grid item display="grid">
              {!isLoading ? (
                <>
                  {filteredDepots ? (
                    <Grid container direction="column" wrap="nowrap" gap="10px">
                      {filteredDepots?.length == 0 && (
                        <Grid item display="grid">
                          <span
                            style={{
                              fontSize: "16px",
                              color: "#0B243C",
                              fontFamily: "Poppins",
                            }}
                          >{`Aucun dépôt n'a été trouvée.`}</span>
                        </Grid>
                      )}
                      {filteredDepots.slice(0, maxItem).map((depot) => {
                        return (
                          <Grid item display="grid" key={depot?._id}>
                            <GenericCard
                              rightPin={
                                depot?.waitingForRevision
                                  ? `En attente de révision`
                                  : undefined
                              }
                              Header={
                                <Grid
                                  container
                                  direction="column"
                                  wrap="nowrap"
                                  gap="5px"
                                >
                                  <Grid item display="grid">
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        color: "#0B243C",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      {depot?.title
                                        ? depot.title
                                        : "Sans titre"}
                                    </span>
                                  </Grid>
                                  {depot?.created && (
                                    <Grid item display="grid">
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          color: "#0B243C",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        {`Créé le ` +
                                          genericGetFromTimestamp(
                                            depot.created,
                                            "dd/mm/yyyy à hh:min"
                                          )}
                                      </span>
                                    </Grid>
                                  )}
                                  {depot?.lastUpdate && (
                                    <Grid item display="grid">
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          color: "#0B243C",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        {`Dernière révision le ` +
                                          genericGetFromTimestamp(
                                            depot.lastUpdate,
                                            "dd/mm/yyyy à hh:min"
                                          )}
                                      </span>
                                    </Grid>
                                  )}
                                </Grid>
                              }
                              rightButtons={[
                                {
                                  Icon: DownloadRounded,
                                  disable: someFileDownloading,
                                  title: "Télécharger le fichier",
                                  onClick: () => {
                                    setSomeFileDownloading(true);
                                    makeAPIRequest(
                                      "get",
                                      `/depot/${depot?._id}/file`,
                                      null,
                                      "v3"
                                    )
                                      .then((res) => {
                                        const depotInfos = res?.data;

                                        if (
                                          depotInfos?.fileBase64 &&
                                          depotInfos?.fileName
                                        ) {
                                          const base64Content =
                                            depotInfos.fileBase64.split(",")[1];
                                          const mimeType = depotInfos.fileBase64
                                            .split(",")[0]
                                            .match(/:(.*?);/)[1];

                                          const binaryString =
                                            atob(base64Content);
                                          const binaryLen = binaryString.length;
                                          const bytes = new Uint8Array(
                                            binaryLen
                                          );
                                          for (let i = 0; i < binaryLen; i++) {
                                            bytes[i] =
                                              binaryString.charCodeAt(i);
                                          }

                                          const fileBlob = new Blob([bytes], {
                                            type: mimeType,
                                          });

                                          const downloadLink =
                                            document.createElement("a");
                                          downloadLink.href =
                                            URL.createObjectURL(fileBlob);
                                          downloadLink.download =
                                            depotInfos.fileName;

                                          downloadLink.click();

                                          URL.revokeObjectURL(
                                            downloadLink.href
                                          );

                                          snackbarOpenUse.success(
                                            "Fichier téléchargé avec succès !"
                                          );
                                        } else {
                                          snackbarOpenUse.error(
                                            "Fichier non valide ou manquant."
                                          );
                                        }
                                      })
                                      .catch((err) => {
                                        snackbarOpenUse.error(
                                          "Impossible de télécharger ce fichier.",
                                          err
                                        );
                                      })
                                      .finally(() => {
                                        setSomeFileDownloading(false);
                                      });
                                  },
                                  color: "rgb(11, 36, 60)",
                                },
                                depot?.waitingForRevision
                                  ? undefined
                                  : {
                                      Icon: SupervisorAccountRounded,
                                      title: "Demander une révision",
                                      disable: someFileWaitingRevision,
                                      onClick: () => {
                                        setSomeFileWaitingRevision(true);
                                        makeAPIRequest(
                                          "get",
                                          `/depot/${depot?._id}/ask_revision`,
                                          null,
                                          "v3"
                                        )
                                          .then((res) => {
                                            loadAllFiles();
                                          })
                                          .catch((err) => {
                                            snackbarOpenUse.error(
                                              "Impossible de demander une révision.",
                                              err
                                            );
                                          })
                                          .finally(() => {
                                            setSomeFileWaitingRevision(false);
                                          });
                                      },
                                      color: "rgb(11, 36, 60)",
                                    },
                                {
                                  Icon: DeleteRounded,
                                  title: "Supprimer",
                                  onClick: () => {
                                    setIsDeletePopup(depot);
                                  },
                                  color: "rgb(11, 36, 60)",
                                },
                              ].filter((x) => x !== undefined)}
                            >
                              <>
                                <Grid
                                  container
                                  direction="column"
                                  wrap="nowrap"
                                  gap="5px"
                                >
                                  <Grid item display="grid">
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        color: "#0B243C",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      {depot?.fileName
                                        ? `Nom du fichier : ` + depot.fileName
                                        : "Nom de fichier non renseigné."}
                                    </span>
                                  </Grid>
                                  {depot?.description && (
                                    <Grid item display="grid">
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          color: "#0B243C",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        {depot?.description}
                                      </span>
                                    </Grid>
                                  )}
                                  {depot?.revisionComment && (
                                    <Grid item display="grid" paddingTop="20px">
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          color: "#0B243C",
                                          fontFamily: "Poppins",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Commentaire : {depot?.revisionComment}
                                      </span>
                                    </Grid>
                                  )}
                                </Grid>
                              </>
                            </GenericCard>
                          </Grid>
                        );
                      })}
                      {myDepots?.length > 0 && (
                        <MoreOrLessButtons
                          maxItem={maxItem}
                          setMaxItem={setMaxItem}
                          items={myDepots}
                          marginTop="0px"
                        />
                      )}
                    </Grid>
                  ) : (
                    <span>Il n'y a aucun fichier à afficher.</span>
                  )}
                </>
              ) : (
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const EspaceDepotPage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="espace_depot" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="espace_depot" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
        >
          <EspaceDepotBox {...props} />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default EspaceDepotPage;
