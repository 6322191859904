import { Grid } from "@mui/material";
import * as React from "react";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import BoxTitle from "../components/Library/Box/BoxTitle";
import useAppSelector from "../redux/useAppSelector";
import Register from "../components/register";
import { useHistory } from "react-router";

const AdminCreateAccountBox: React.FC<any> = (props) => {
  const history = useHistory();
  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        display="flex"
        paddingLeft="41px"
        paddingTop="26px"
        paddingRight="41px"
        paddingBottom="60px"
        minWidth="320px"
        style={{
          background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
          borderRadius: "24px",
        }}
      >
        <Grid item display="grid">
          <Grid container display="flex" direction="column" wrap="nowrap">
            <Grid item display="grid">
              <BoxTitle first="Création" second="de compte" />
            </Grid>
            <Grid item display="grid">
              <Register history={history} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const AdminCreateAccountPage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="admin_create_account" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="admin_create_account" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
          minWidth={700}
        >
          <AdminCreateAccountBox />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default AdminCreateAccountPage;
