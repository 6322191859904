import { Grid } from "@mui/material";
import * as React from "react";
import BoxTitle from "../components/Library/Box/BoxTitle";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import exportMessage from "../components/Messages/ExportMessage";
import MessagesContent from "../components/Messages/MessagesContent";
import TutorialService from "../services/TutorialService";
import FullPage from "./FullPage";
import useAppSelector from "../redux/useAppSelector";

const MessagesProgrammesBox: React.FC<any> = (props) => {
  const windowWidth = useAppSelector((state) => state.windowReducer.windowWidth);
  const [isLoading, setIsLoading] = React.useState(false);
  const user = props.user;
  const createFile = () => {
    setIsLoading(true);
    TutorialService.getMessages()
      .then((res: any) => {
        const result = res.data.data.sended_conseils;
        if (!result) throw new Error("Une erreur est survenue");
        exportMessage(result, user);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
    };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      maxWidth="1360px"
      minWidth="600px"
      style={{
        background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <Grid
          container
          display="flex"
          direction="row"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item display="grid">
            <BoxTitle first="Messages" second="Programmés" />
          </Grid>

          <Grid item display="grid" paddingLeft='20px'>
          </Grid>

        </Grid>
        <Grid
          item
          display="grid"
          paddingTop="40px"
          paddingLeft="8%"
          paddingRight="8%"
        >
          <MessagesContent isSheduled={true} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const ScheduledConseilsPage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector((state) => state.windowReducer.windowWidth);

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="scheduled_conseils" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="scheduled_conseils" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
        >
          <MessagesProgrammesBox {...props} />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default ScheduledConseilsPage;
