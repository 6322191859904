import { Grid } from "@mui/material";
import * as React from "react";
import OrangeCheckBox from "../../../Fields/CheckBox/OrangeCheckBox";
import FullField from "../../../Fields/FullField";
import MultilineInput from "../../../Library/Inputs/MultilineInput";
import TextInput from "../../../Library/Inputs/TextInput";
import { emailValidator } from "../../SendResponsePopup";
import Creatable from "react-select/creatable";
import { IForm } from "../../../../types/FormTypes";
import {
  GenericLongText,
  GenericTextInput,
} from "../../../../pages/CreateOrEditChatbotLexPage";
import isPositiveNumber from "../../../../utils/isPositiveNumber";
import { IChatbot } from "../../../Chatbot/initObjects/initChatbot";
import useAppSelector from "../../../../redux/useAppSelector";
import { GenericSelect } from "../../../../pages/QuestionnairePage";

export const DisplayRelanceSMSQuestionnaireInteractif: React.FC<any> = (
  props
) => {
  const { setChatbotData } = props;
  const chatbotData: IChatbot = props.chatbotData;

  return (
    <Grid container direction="column" wrap="nowrap" gap="15px">
      <Grid item display="grid">
        <OrangeCheckBox
          value={chatbotData?.sendSMSRelance}
          onClick={() => {
            setChatbotData((x) => {
              return {
                ...x,
                sendSMSRelance: x?.sendSMSRelance ? false : true,
              };
            });
          }}
          text="Envoyer des SMS de relance"
        />
      </Grid>
      {chatbotData?.sendSMSRelance && (
        <>
          <Grid item display="grid">
            <Grid container direction="row" alignItems="center" spacing="10px">
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    letterSpacing: "0px",
                    fontSize: "16px",
                    color: "#0B243C",
                  }}
                >
                  Tous les
                </span>
              </Grid>
              <Grid item display="grid">
                <div style={{ maxWidth: "65px" }}>
                  <GenericTextInput
                    fieldTitle=""
                    id="interval-sms-relance-input"
                    value={chatbotData?.dayIntervalSMSRelance}
                    onChange={(e) => {
                      if (
                        e?.target?.value?.length > 0 &&
                        !isPositiveNumber(e.target.value)
                      ) {
                        return;
                      }
                      setChatbotData((x) => {
                        return {
                          ...x,
                          dayIntervalSMSRelance: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    letterSpacing: "0px",
                    fontSize: "16px",
                    color: "#0B243C",
                  }}
                >
                  jour(s) pendant
                </span>
              </Grid>
              <Grid item display="grid">
                <div style={{ maxWidth: "65px" }}>
                  <GenericTextInput
                    fieldTitle=""
                    id="duration-sms-relance-input"
                    value={chatbotData?.durationSMSRelance}
                    onChange={(e) => {
                      if (
                        e?.target?.value?.length > 0 &&
                        !isPositiveNumber(e.target.value)
                      ) {
                        return;
                      }
                      setChatbotData((x) => {
                        return {
                          ...x,
                          durationSMSRelance: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    letterSpacing: "0px",
                    fontSize: "16px",
                    color: "#0B243C",
                  }}
                >
                  jour(s)
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="grid" paddingTop="5px">
            <GenericLongText
              fieldTitle="Contenu"
              usableVariables={["nom_patient", "prenom_patient"]}
              height="150px"
              isMandatory={true}
              maxLength={500}
              id="content-sms-relance-input"
              value={chatbotData?.contentSMSRelance}
              onChange={(e) => {
                setChatbotData((x) => {
                  return { ...x, contentSMSRelance: e } as IForm;
                });
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const DisplayRelanceSMS = (props: any) => {
  const { setQuestionnaireData } = props;
  const questionnaireData: IForm = props.questionnaireData;
  const questionnairesInteractifs: IChatbot[] = useAppSelector(
    (x) => x.questionnaireInteractifReducer.list
  );

  return (
    <Grid container direction="column" wrap="nowrap" gap="15px">
      <Grid item display="grid">
        <OrangeCheckBox
          value={questionnaireData?.sendSMSRelance}
          onClick={() => {
            setQuestionnaireData((x) => {
              return {
                ...x,
                sendSMSRelance: x?.sendSMSRelance ? false : true,
              };
            });
          }}
          text="Envoyer des SMS de relance"
        />
      </Grid>
      {questionnaireData?.sendSMSRelance && (
        <>
          <Grid item display="grid">
            <Grid container direction="row" alignItems="center" spacing="10px">
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    letterSpacing: "0px",
                    fontSize: "16px",
                    color: "#0B243C",
                  }}
                >
                  Tous les
                </span>
              </Grid>
              <Grid item display="grid">
                <div style={{ maxWidth: "65px" }}>
                  <GenericTextInput
                    fieldTitle=""
                    id="interval-sms-relance-input"
                    value={questionnaireData?.dayIntervalSMSRelance}
                    onChange={(e) => {
                      if (
                        e?.target?.value?.length > 0 &&
                        !isPositiveNumber(e.target.value)
                      ) {
                        return;
                      }
                      setQuestionnaireData((x) => {
                        return {
                          ...x,
                          dayIntervalSMSRelance: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    letterSpacing: "0px",
                    fontSize: "16px",
                    color: "#0B243C",
                  }}
                >
                  jour(s) pendant
                </span>
              </Grid>
              <Grid item display="grid">
                <div style={{ maxWidth: "65px" }}>
                  <GenericTextInput
                    fieldTitle=""
                    id="duration-sms-relance-input"
                    value={questionnaireData?.durationSMSRelance}
                    onChange={(e) => {
                      if (
                        e?.target?.value?.length > 0 &&
                        !isPositiveNumber(e.target.value)
                      ) {
                        return;
                      }
                      setQuestionnaireData((x) => {
                        return {
                          ...x,
                          durationSMSRelance: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    letterSpacing: "0px",
                    fontSize: "16px",
                    color: "#0B243C",
                  }}
                >
                  jour(s)
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="grid">
            <OrangeCheckBox
              value={
                questionnaireData?.contentSMSRelanceReplaceByLastSMS === true
                  ? true
                  : false
              }
              onClick={() => {
                setQuestionnaireData((x) => {
                  return {
                    ...x,
                    contentSMSRelanceReplaceByLastSMS:
                      x?.contentSMSRelanceReplaceByLastSMS ? false : true,
                  };
                });
              }}
              text="Renvoyer le dernier SMS d'un parcours ou d'un conseil que le patient a reçu"
            />
          </Grid>
          {!questionnaireData?.contentSMSRelanceReplaceByLastSMS && (
            <Grid item display="grid" paddingTop="5px">
              <GenericLongText
                fieldTitle="Contenu"
                usableVariables={[
                  "nom_patient",
                  "prenom_patient",
                  "lien_questionnaire",
                ]}
                height="150px"
                maxLength={500}
                id="content-sms-relance-input"
                value={questionnaireData?.contentSMSRelance}
                onChange={(e) => {
                  setQuestionnaireData((x) => {
                    return { ...x, contentSMSRelance: e } as IForm;
                  });
                }}
              />
              {!questionnaireData?.contentSMSRelance?.includes(
                "{lien_questionnaire}"
              ) &&
                !questionnaireData?.contentSMSRelance?.includes("/#/q/") && (
                  <span
                    className="field_star"
                    style={{ textAlign: "left", fontSize: "12px" }}
                  >
                    {`* Attention, le lien du questionnaire ne sera pas envoyé sauf action de votre part, en ajoutant la variable {lien_questionnaire}.`}
                  </span>
                )}
            </Grid>
          )}
        </>
      )}
      <Grid item display="grid">
        <OrangeCheckBox
          value={questionnaireData?.sendQuestionnaireInteractif?.isActive}
          onClick={() => {
            setQuestionnaireData((x) => {
              return {
                ...x,
                sendQuestionnaireInteractif: {
                  ...x?.sendQuestionnaireInteractif,
                  isActive: x?.sendQuestionnaireInteractif?.isActive
                    ? false
                    : true,
                },
              };
            });
          }}
          text="Si aucune réponse, envoyer un questionnaire interactif"
        />
      </Grid>
      {questionnaireData?.sendQuestionnaireInteractif?.isActive && (
        <>
          <Grid item display="grid" width="350px">
            <GenericSelect
              fieldTitle=""
              maxMenuHeight="150px"
              isMandatory={false}
              value={
                questionnaireData?.sendQuestionnaireInteractif?.id &&
                questionnairesInteractifs?.length > 0
                  ? {
                      value: questionnaireData?.sendQuestionnaireInteractif?.id,
                      label: questionnairesInteractifs?.find(
                        (x) =>
                          x?._id ==
                          questionnaireData?.sendQuestionnaireInteractif?.id
                      )?.title,
                    }
                  : undefined
              }
              id="select-quest-interactif"
              placeholder="Choix du questionnaire interactif"
              isClearable={true}
              options={
                questionnairesInteractifs
                  ? questionnairesInteractifs
                      ?.filter((x) => x?.title && x?._id)
                      ?.map((x) => {
                        return {
                          label: x?.title,
                          value: x?._id,
                        };
                      })
                  : []
              }
              onChange={(e) => {
                setQuestionnaireData((x) => {
                  return {
                    ...x,
                    sendQuestionnaireInteractif: {
                      ...x?.sendQuestionnaireInteractif,
                      id: e?.value,
                    },
                  };
                });
              }}
            />
          </Grid>
          <Grid item display="grid">
            <Grid container direction="row" alignItems="center" spacing="10px">
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    letterSpacing: "0px",
                    fontSize: "16px",
                    color: "#0B243C",
                  }}
                >
                  Au bout de
                </span>
              </Grid>
              <Grid item display="grid">
                <div style={{ maxWidth: "65px" }}>
                  <GenericTextInput
                    fieldTitle=""
                    id="send-interactive-form-days-after-input"
                    value={
                      questionnaireData?.sendQuestionnaireInteractif?.dayAfter
                    }
                    onChange={(e) => {
                      if (
                        e?.target?.value?.length > 0 &&
                        !isPositiveNumber(e.target.value)
                      ) {
                        return;
                      }
                      setQuestionnaireData((x) => {
                        return {
                          ...x,
                          sendQuestionnaireInteractif: {
                            ...x?.sendQuestionnaireInteractif,
                            dayAfter: e.target.value,
                          },
                        };
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    letterSpacing: "0px",
                    fontSize: "16px",
                    color: "#0B243C",
                  }}
                >
                  jour(s) sans réponse
                </span>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DisplayRelanceSMS;
