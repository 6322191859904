import * as React from "react";
import { Grid } from "@mui/material";
import isPdf from "../../../utils/handle_files/isPdf";
import isImage from "../../../utils/handle_files/isImage";
import DisplayText from "./Fields/DisplayText";
import DisplayLongText from "./Fields/DisplayLongText";
import DisplaySimpleCheck from "./Fields/DisplaySimpleCheck";
import DisplayListDeroulante from "./Fields/DisplayListDeroulante";
import DisplayUniqueChoice, {
  DisplayDateSortie,
  DisplayPieceJointe,
} from "./Fields/DisplayUniqueChoice";
import DisplayButtons, { formatResponse } from "./DisplayButtons";
import DisplayEchelleDeNotation from "./Fields/DisplayEchelleNotation";
import DisplayTextAndImage from "./Fields/DisplayTextAndImage";
import GreenButton from "../../Library/Buttons/GreenButton/GreenButton";
import DisplayError from "../../Library/Errors/DisplayError";
import makeAPIRequest from "../../../utils/makeAPIRequest";
import { openSnackbar } from "../../../redux/snackbarReducer";
import { useDispatch } from "react-redux";
import useQuery from "../../../utils/hooks/useQuery";
import formatValue from "../Response/formatValue";
import resizeText from "../../../utils/resizeText";
import FullField from "../../Fields/FullField";
import MultilineInput from "../../Library/Inputs/MultilineInput";
import DisplayDate, { DisplayAnnuaireMSSante } from "./Fields/DisplayDate";
import DisplayAdditionAuto from "./Fields/DisplayAdditionAuto";
import {
  ConditionField,
  IField,
  IForm,
  ISection,
} from "../../../types/FormTypes";
import CheckBoxField from "../../Fields/CheckBox/CheckBoxField";
import DisplayMedecinsTraitants from "./Fields/DisplayMedecinsTraitants";
import DisplayAttributPatient from "./Fields/DisplayAttributPatient";
import DisplayProtocole from "./Fields/DisplayProtocole";
import DisplayMedecinDelegant from "./Fields/DisplayMedecinDelegant";
import DisplayStructure from "./Fields/DisplayStructure";
import { getFieldByFieldId } from "../EditQuestionnaire/Fields/DisplayConfirmMessage";
import DisplayFormatedResponse from "../Response/DisplayFormatedResponse";
import { getFormatedResponse } from "../SendResponseMedimailPopup";
import { createPDFWithElementIds } from "../../../utils/exportPdf";
import { BlueButton } from "../../../pages/QuestionnairePage";
import { Exclusion } from "../EditQuestionnaire/QuestionnaireEdit";

export function calcAgeFromBirthDate(birthDate: string): number {
  if (birthDate) {
    const splited = birthDate.split("-");
    if (splited?.[2]) {
      if (parseInt(splited[2]) < 1800) {
        return 1001;
      }
    }
  }
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }

  return age;
}

export const getOnlySectionWithGoodCondition = (
  questionnaireData: Partial<IForm>
): ISection[] => {
  if (!questionnaireData || !questionnaireData?.sections) {
    return [];
  }
  const responses = formatResponse(questionnaireData, "", "", "");
  const validSection = [];
  for (const section of questionnaireData?.sections) {
    if (section?.conditionsInfos?.conditionsList?.length > 0) {
      let isFounded = false;
      for (const condition of section.conditionsInfos.conditionsList) {
        const isValid = isValidConditionResponse(
          condition.conditions,
          responses.fields,
          condition.conditionType,
          questionnaireData as any
        );
        if (isValid === true) {
          isFounded = true;
          validSection.push(section);
          break;
        }
      }
      if (isFounded === false) {
        validSection.push(undefined);
      }
    } else {
      validSection.push(section);
    }
  }
  return validSection;
};

export const getConsentementPhrase = (
  questionnaireData: Partial<IForm>,
  excludeFieldId: string
): string => {
  for (const section of questionnaireData?.sections) {
    for (const fieldRow of section?.fields) {
      for (const field of fieldRow) {
        if (
          field?.type === "medecins_traitants" &&
          field?.medecinsTraitantInfos?.consentText &&
          field?.id !== excludeFieldId
        ) {
          return field.medecinsTraitantInfos.consentText;
        }
      }
    }
  }
  return undefined;
};

export const cleanHTML = (html: string): string => {
  let newHtml = html;
  if (!newHtml) {
    return "";
  }
  if (html.startsWith('<p dir = "auto">') && html.endsWith("</p>\n")) {
    newHtml = html.substring(
      '<p dir = "auto">'.length,
      newHtml.length - "</p>\n".length
    );
  }
  return newHtml?.replaceAll('<p dir = "auto"></p>', "<br/>");
};

function addHttpIfNecessary(url) {
  if (!/^https?:\/\//i.test(url) && !/^www\./i.test(url)) {
    url = "http://" + url;
  }
  return url;
}

const spacingBetweenFormText = "25px";

export const getOnlyFieldWithGoodCondition = (
  fieldRows: IField[][],
  questionnaireData: IForm
) => {
  if (!fieldRows) {
    return [];
  }
  const responses = formatResponse(questionnaireData, "", "", "");
  const newFieldRows = [];

  for (const fieldRow of fieldRows) {
    const newFieldRow = [];
    for (const field of fieldRow) {
      if (
        field?.type === "structure" &&
        field?.structureInfos?.structuresList?.length > 0
      ) {
        const foundedStructure = field.structureInfos.structuresList.find(
          (x) => x?.id == field?.value?.[0]
        );
        if (foundedStructure && foundedStructure?.deleguesList?.length > 0) {
          const foundedDelegue = foundedStructure.deleguesList.find(
            (x) => x?.id == field?.value?.[1]
          );
          if (
            foundedDelegue &&
            foundedDelegue?.dateDeclarationInfos?.dateDeclarationsList?.length >
              0
          ) {
            for (const dateDeclaration of foundedDelegue.dateDeclarationInfos
              .dateDeclarationsList) {
              const isValid = isValidConditionResponse(
                dateDeclaration.conditions,
                responses.fields,
                dateDeclaration.conditionType,
                questionnaireData
              );
              if (isValid) {
                field.value[2] = dateDeclaration.date;
              }
            }
          }
        }
      }
      if (field?.conditionsInfos?.conditionsList?.length > 0) {
        for (const condition of field.conditionsInfos.conditionsList) {
          const isValid = isValidConditionResponse(
            condition.conditions,
            responses.fields,
            condition.conditionType,
            questionnaireData
          );
          if (isValid === true) {
            newFieldRow.push(field);
            break;
          }
        }
      } else {
        newFieldRow.push(field);
      }
    }
    newFieldRows.push(newFieldRow);
  }
  return newFieldRows;
};

const DisplaySection: React.FC<any> = (props) => {
  const {
    questionnaireData,
    setQuestionnaireData,
    setExclusion,
    displayMandatory,
    sectionIndex,
  } = props;

  const fieldRows: IField[][] = getOnlyFieldWithGoodCondition(
    questionnaireData?.sections?.[sectionIndex]?.fields,
    questionnaireData
  );

  /* Check exclusions */
  React.useEffect(() => {
    if (questionnaireData?.exclusions?.length > 0) {
      const responses = formatResponse(questionnaireData, "", "", "");
      for (const exclusion of questionnaireData.exclusions) {
        const isValid = isValidConditionResponse(
          exclusion.conditions,
          responses.fields,
          exclusion.conditionType,
          questionnaireData as any,
          true
        );
        if (isValid) {
          setExclusion(exclusion);
        }
      }
    }
  }, [questionnaireData]);

  return (
    <Grid container direction="column" wrap="nowrap">
      {fieldRows?.filter((x) => x?.length > 0)?.length === 0 && (
        <Grid item display="grid">
          <span style={{ fontFamily: "Poppins", fontSize: "16px" }}>
            Aucun champ
          </span>
        </Grid>
      )}
      {fieldRows?.map((fieldRow, fieldRowIndex) => {
        if (!fieldRow || fieldRow?.length == 0) {
          return <></>;
        }
        return (
          <Grid
            item
            key={fieldRowIndex}
            display="grid"
            marginTop={
              fieldRowIndex !== fieldRows.findIndex((x) => x && x?.length > 0)
                ? "15px"
                : undefined
            }
          >
            <Grid container gap="15px" direction="row">
              {fieldRow?.map((field: IField, fieldIndex) => {
                const calcValue = (v: any) => {
                  if (v && typeof v === "object") {
                    const keys = Object.keys(v);
                    if (keys.length > 0)
                      return keys?.map((e) => e?.toLowerCase());
                    else return "";
                  }
                  return v;
                };
                const stops =
                  field?.stops && Array.isArray(field.stops)
                    ? field?.stops
                        ?.filter((x: any) => x?.value != undefined)
                        ?.map((element) =>
                          element?.value
                            ? element?.value?.toLowerCase()
                            : element?.value
                        )
                    : [];
                const valueResult = calcValue(field?.value);
                if (
                  stops &&
                  typeof valueResult === "string" &&
                  stops.includes(valueResult?.toLowerCase())
                ) {
                  props?.setStopPopup(field);
                }
                if (
                  stops &&
                  Array.isArray(valueResult) &&
                  stops.some((e) => valueResult.includes(e))
                ) {
                  props?.setStopPopup(field);
                }
                return (
                  <Grid
                    item
                    xs={field?.type === "date" ? false : true}
                    key={field?.id}
                    display="grid"
                    minWidth="200px"
                  >
                    <Grid container direction="column" wrap="nowrap">
                      <Grid item display="grid">
                        {field?.type === "display_text_and_image" && (
                          <DisplayTextAndImage
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "text" && (
                          <DisplayText
                            disableError={displayMandatory}
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "long_text" && (
                          <DisplayLongText
                            disableError={displayMandatory}
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "date" && (
                          <DisplayDate
                            disableError={displayMandatory}
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "annuaire_mssante" && (
                          <DisplayAnnuaireMSSante
                            disableError={displayMandatory}
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "simple_check" && (
                          <DisplaySimpleCheck
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "liste_deroulante" && (
                          <DisplayListDeroulante
                            disableError={displayMandatory}
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "medecins_traitants" && (
                          <DisplayMedecinsTraitants
                            disableError={displayMandatory}
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "protocole" && (
                          <DisplayProtocole
                            disableError={displayMandatory}
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "medecin_delegant" && (
                          <DisplayMedecinDelegant
                            disableError={displayMandatory}
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "structure" && (
                          <DisplayStructure
                            disableError={displayMandatory}
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "date_sortie" && (
                          <DisplayDateSortie
                            disableError={displayMandatory}
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "piece_jointe" && (
                          <DisplayPieceJointe
                            disableError={displayMandatory}
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "attribut_patient" && (
                          <DisplayAttributPatient
                            disableError={displayMandatory}
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "unique_choice" && (
                          <DisplayUniqueChoice
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "echelle_notation" && (
                          <DisplayEchelleDeNotation
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                        {field?.type === "addition_auto" && (
                          <DisplayAdditionAuto
                            field={field}
                            fieldIndex={fieldIndex}
                            fieldRowIndex={fieldRowIndex}
                            {...props}
                          />
                        )}
                      </Grid>
                      {displayMandatory &&
                        !field?.value &&
                        field?.isMandatory &&
                        !["display_text_and_image"].includes(field?.type) && (
                          <Grid item display="grid" paddingTop="10px">
                            <DisplayError errorMessage="Ce champ est obligatoire." />
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

const DisplayPagesMenu = (props: {
  actualPage: number;
  sectionTitles: string[];
  setActualPage: any;
}): JSX.Element => {
  const { actualPage, sectionTitles, setActualPage } = props;

  const getBorder = (index) => {
    if (actualPage === index) {
      return "1px solid rgb(79, 201, 154)";
    }
    if (index < actualPage) {
      return "1px solid transparent";
    }
    if (index > actualPage) {
      return "1px solid #FAB614";
    }
  };

  const getTextColor = (index, isNotNumber = false) => {
    if (actualPage === index) {
      return "rgb(79, 201, 154)";
    }
    if (index < actualPage) {
      return isNotNumber ? "rgb(79, 201, 154)" : "#fff";
    }
    if (index > actualPage) {
      return "#FAB614";
    }
  };
  const getBackground = (index) => {
    if (actualPage === index) {
      return "transparent";
    }
    if (index < actualPage) {
      return "rgb(79, 201, 154)";
    }
    if (index > actualPage) {
      return "transparent";
    }
  };

  return (
    <Grid container direction="row" gap="15px" justifyContent="space-around">
      {sectionTitles.map((sectionTitle, index) => {
        return (
          <Grid item display="grid" maxWidth="140px" key={index}>
            <Grid
              container
              direction="column"
              width="fit-content"
              wrap="nowrap"
              alignItems="center"
              gap="5px"
            >
              <Grid item display="grid">
                <Grid container direction="row" wrap="nowrap">
                  <Grid item display="grid">
                    <div
                      style={{
                        height: "30px",
                        borderRadius: "30px",
                        width: "30px",
                        backgroundColor: getBackground(index),
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        border: getBorder(index),
                        fontSize: "13px",
                        fontFamily: "Poppins",
                        color: getTextColor(index),
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {index + 1}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    textAlign: "center",
                    color: getTextColor(index, true),
                  }}
                >
                  {sectionTitle ? sectionTitle : "Section"}
                </span>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

const DisplayPagination = (props: {
  setActualPage: any;
  setIsSended: any;
  setResponse: any;
  setDisplayMandatory: any;
  displayMandatory: boolean;
  actualPage: number;
  section: any;
  questionnaireData: any;
  isPreview: boolean;
  length: number;
  comment?: string;
  stopPopup?: any;
}): JSX.Element => {
  const {
    setActualPage,
    actualPage,
    length,
    section,
    setIsSended,
    setResponse,
    setDisplayMandatory,
    questionnaireData,
    isPreview,
    stopPopup,
    comment,
  } = props;

  const dispatch = useDispatch();
  const patientUrl = useQuery().get("p");

  const executeSubmit = () => {
    for (const fieldRow of section?.fields) {
      for (const field of fieldRow) {
        if (
          !field?.value &&
          field?.isMandatory &&
          !["display_text_and_image"].includes(field?.type)
        ) {
          setDisplayMandatory(true);
          return;
        }
      }
    }
    if (!isPreview) {
      const r = formatResponse(
        questionnaireData,
        patientUrl,
        stopPopup?.id,
        comment,
        true,
        true
      );
      makeAPIRequest("post", "/questions/response/create", r, "v3")
        .then((res) => {
          setIsSended(true);
          setResponse(getFormatedResponse(r));
        })
        .catch((err) => {
          dispatch(
            openSnackbar({
              message: `Impossible d'envoyer votre réponse`,
              type: "error",
              duration: 3000,
            })
          );
        });
    }
    setDisplayMandatory(false);
    setIsSended(true);
  };

  const jumpToNextPage = () => {
    for (const fieldRow of section?.fields) {
      for (const field of fieldRow) {
        if (
          !field?.value &&
          field?.isMandatory &&
          !["display_text_and_image"].includes(field?.type)
        ) {
          setDisplayMandatory(true);
          return;
        }
      }
    }
    setDisplayMandatory(false);
    setActualPage((p) => p + 1);
  };

  if (actualPage === 0 && length > 1) {
    // Display only NEXT
    return (
      <Grid
        container
        direction="row"
        wrap="wrap"
        gap="15px"
        justifyContent="center"
      >
        <GreenButton
          animation={true}
          text="Suivant"
          onClick={() => jumpToNextPage()}
        />
      </Grid>
    );
  } else if (actualPage === 0 && length === 1) {
    // Display only SUBMIT
    return (
      <Grid
        container
        direction="row"
        wrap="wrap"
        gap="15px"
        justifyContent="center"
      >
        <GreenButton
          animation={true}
          text="Je valide mes réponses"
          onClick={() => {
            executeSubmit();
          }}
        />
      </Grid>
    );
  } else if (actualPage === length - 1 && length > 1) {
    // Display PREV and SUBMIT
    return (
      <Grid
        container
        direction="row"
        wrap="wrap"
        gap="15px"
        justifyContent="center"
      >
        <Grid item display="grid">
          <GreenButton
            animation={true}
            text="Précédent"
            onClick={() => setActualPage((p) => p - 1)}
          />
        </Grid>
        <Grid item display="grid">
          <GreenButton
            animation={true}
            text="Je valide mes réponses"
            onClick={() => {
              executeSubmit();
            }}
          />
        </Grid>
      </Grid>
    );
  } else {
    // Display NEXT and PREV
    return (
      <Grid
        container
        direction="row"
        wrap="wrap"
        gap="15px"
        justifyContent="center"
      >
        <Grid item display="grid">
          <GreenButton
            animation={true}
            text="Précédent"
            onClick={() => setActualPage((p) => p - 1)}
          />
        </Grid>
        <Grid item display="grid">
          <GreenButton
            animation={true}
            text="Suivant"
            onClick={() => jumpToNextPage()}
          />
        </Grid>
      </Grid>
    );
  }
};

export const isValidConditionResponse = (
  conditions: ConditionField[],
  responseField: any[],
  conditionType: any,
  questionnaireData: IForm,
  dynamicDate = false
) => {
  let nbValidConditions = 0;
  let totalChecked = 0;
  for (const condition of conditions) {
    totalChecked++;
    let conditionValue = condition?.value ? condition.value.toLowerCase() : "";
    let foundedFieldResponse = responseField.find(
      (y) => y.id === condition.fieldId
    );
    if (!foundedFieldResponse && conditionValue == "non renseigné / vide") {
      foundedFieldResponse = {
        value: [""],
        fieldTitle: "",
        id: condition?.fieldId,
        sectionTitle: "",
        fieldType: "",
      };
    }
    if (conditionValue == "non renseigné / vide") {
      conditionValue = "";
    }
    if (!foundedFieldResponse) {
      continue;
    }
    const field = getFieldByFieldId(
      questionnaireData,
      foundedFieldResponse?.id
    );

    let value = formatValue(foundedFieldResponse.value).toLowerCase();

    if (
      field?.type === "protocole" &&
      field?.protocoleInfos?.protocolesList?.length > 0
    ) {
      const foundedProtocole = field?.protocoleInfos?.protocolesList?.find(
        (x) => x?.id == condition?.value
      );
      if (foundedProtocole) {
        conditionValue = foundedProtocole?.nature?.toLowerCase();
      }
    }
    if (
      field?.type === "attribut_patient" &&
      field?.attributPatientType === "birth_date"
    ) {
      const age = calcAgeFromBirthDate(value);
      console.log(age);
      value = age.toString();
      if (dynamicDate && age > 1000) {
        value = "";
      }
    }
    if (condition.type === "=") {
      if (value === conditionValue) {
        nbValidConditions++;
        continue;
      }
    }
    if (condition.type === "!=") {
      if (value !== conditionValue) {
        nbValidConditions++;
        continue;
      }
    }
    if (condition.type === "contains") {
      if (value.includes(conditionValue)) {
        nbValidConditions++;
        continue;
      }
    }
    if (condition.type === ">=") {
      if (parseFloat(value) >= parseFloat(conditionValue)) {
        nbValidConditions++;
        continue;
      }
    }
    if (condition.type === "<") {
      if (parseFloat(value) < parseFloat(conditionValue)) {
        nbValidConditions++;
        continue;
      }
    }
    if (condition.type === ">") {
      if (parseFloat(value) > parseFloat(conditionValue)) {
        nbValidConditions++;
        continue;
      }
    }
    if (condition.type === "<=") {
      if (parseFloat(value) <= parseFloat(conditionValue)) {
        nbValidConditions++;
        continue;
      }
    }
  }
  if (conditionType === "zero" && nbValidConditions === 0) {
    return true;
  } else if (conditionType === "or" && nbValidConditions > 0) {
    return true;
  } else if (
    conditionType === "and" &&
    nbValidConditions > 0 &&
    totalChecked === nbValidConditions
  ) {
    return true;
  } else if (conditionType === "more_or_two" && nbValidConditions >= 2) {
    return true;
  } else if (conditionType === "more_or_three" && nbValidConditions >= 3) {
    return true;
  } else if (conditionType === "less_than_three" && nbValidConditions < 3) {
    return true;
  } else if (conditionType === "more_or_four" && nbValidConditions >= 4) {
    return true;
  } else if (conditionType === "less_than_four" && nbValidConditions < 4) {
    return true;
  } else if (conditionType === "more_or_five" && nbValidConditions >= 5) {
    return true;
  } else if (conditionType === "less_than_five" && nbValidConditions < 5) {
    return true;
  } else if (conditionType === "more_or_six" && nbValidConditions >= 6) {
    return true;
  } else if (conditionType === "less_than_six" && nbValidConditions < 6) {
    return true;
  } else if (conditionType === "more_or_seven" && nbValidConditions >= 7) {
    return true;
  } else if (conditionType === "less_than_seven" && nbValidConditions < 7) {
    return true;
  } else if (conditionType === "more_or_eight" && nbValidConditions >= 8) {
    return true;
  } else if (conditionType === "less_than_eight" && nbValidConditions < 8) {
    return true;
  } else if (conditionType === "more_or_nine" && nbValidConditions >= 9) {
    return true;
  } else if (conditionType === "less_than_nine" && nbValidConditions < 9) {
    return true;
  } else if (conditionType === "more_or_ten" && nbValidConditions >= 10) {
    return true;
  } else if (conditionType === "less_than_ten" && nbValidConditions < 10) {
    return true;
  }
  return false;
};

const GenerateQuestionnaire: React.FC<{
  questionnaireData?: Partial<IForm>;
  setQuestionnaireData?: React.Dispatch<React.SetStateAction<Partial<IForm>>>;
  isPreview?: boolean;
}> = (props) => {
  const [displayMandatory, setDisplayMandatory] = React.useState(false);
  const [actualPage, setActualPage] = React.useState(0);
  const [isSended, setIsSended] = React.useState(false);
  const [downloadSendedLoading, setDownloadSendedLoading] =
    React.useState(false);
  const [response, setResponse] = React.useState<any>(undefined);
  const [isStopped, setIsStopped] = React.useState(false);
  const [stopPopup, setStopPopup] = React.useState(null);
  const [exclusion, setExclusion] = React.useState<Exclusion>(undefined);
  const [comment, setComment] = React.useState("");
  const [messageToDisplay, setMessageToDisplay] = React.useState<string>("");
  const { questionnaireData, setQuestionnaireData, isPreview } = props;
  const patientUrl = useQuery().get("p");

  const getResponses = () => {
    const fieldsResult = formatResponse(
      questionnaireData,
      patientUrl,
      stopPopup?.id,
      comment
    )?.fields;
    let responses = fieldsResult?.filter((r) => r?.value?.length > 0);
    const sectionTitles = responses?.map((r) => r?.sectionTitle);
    const allSections = [];
    for (const sectionTitle of sectionTitles) {
      const toAdd = responses.filter(
        (item) => item.sectionTitle === sectionTitle
      );
      responses = responses.filter(
        (item) => item.sectionTitle !== sectionTitle
      );
      if (toAdd && toAdd.length > 0) {
        allSections.push(toAdd);
      }
    }
    return allSections;
  };

  /*
  React.useEffect(() => {
    // update unique choice here
  }, [questionnaireData?.sections]);
  */

  React.useEffect(() => {
    if (!isSended) {
      return;
    }
    if (isStopped) {
      return;
    }
    let message: string = questionnaireData?.confirmMessage;
    let redirect: string = questionnaireData?.defaultRedirectURL;
    let redirectInstant: boolean = questionnaireData?.defaultRedirectInstant;
    const responses = formatResponse(
      questionnaireData,
      patientUrl,
      stopPopup?.id,
      comment
    );
    if (questionnaireData.conditionalConfirmMessages?.length > 0) {
      for (const conditionalConfirmMessage of questionnaireData.conditionalConfirmMessages) {
        const additionnalConditions =
          conditionalConfirmMessage?.additionnalConditions;
        let nbConditions =
          additionnalConditions?.length > 0
            ? additionnalConditions.length + 1
            : 1;
        let nbValid = 0;
        const isValid = isValidConditionResponse(
          conditionalConfirmMessage.conditions,
          responses.fields,
          conditionalConfirmMessage.type,
          questionnaireData as any
        );
        if (isValid) {
          nbValid++;
        }
        if (additionnalConditions && additionnalConditions?.length > 0) {
          for (const additionnalCondition of additionnalConditions) {
            const isValid = isValidConditionResponse(
              additionnalCondition.conditions,
              responses.fields,
              additionnalCondition.type,
              questionnaireData as any
            );
            if (isValid) {
              nbValid++;
            }
          }
        }
        if (nbValid === nbConditions) {
          message = conditionalConfirmMessage.message;
          redirect = conditionalConfirmMessage.redirectUrl;
          redirectInstant = conditionalConfirmMessage.redirectInstant;
          break;
        }
      }
    }

    const doRedirect = () => {
      if (redirect) {
        if (
          redirect.includes("/#/q/") &&
          patientUrl &&
          !redirect.includes("?")
        ) {
          if (
            window.location.href ==
            addHttpIfNecessary(redirect + `?p=${patientUrl}`)
          ) {
            window.location.reload();
          } else {
            window.location.replace(
              addHttpIfNecessary(redirect + `?p=${patientUrl}`)
            );
          }
        } else {
          if (window.location.href == addHttpIfNecessary(redirect)) {
            window.location.reload();
          } else {
            window.location.replace(addHttpIfNecessary(redirect));
          }
        }
      }
    };
    if (redirectInstant) {
      doRedirect();
      return;
    }
    setTimeout(() => {
      doRedirect();
    }, 5000);
    setMessageToDisplay(message);
  }, [questionnaireData, isSended, isStopped, patientUrl]);

  if (stopPopup || (exclusion && !isStopped)) {
    return (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap={spacingBetweenFormText}
      >
        {(questionnaireData?.title || questionnaireData?.visibleTitle) && (
          <Grid item display="grid">
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "26px",
                color: "rgb(11, 36, 60)",
                letterSpacing: "0px",
                textAlign: "center",
                fontWeight: 500,
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              {questionnaireData?.visibleTitle
                ? questionnaireData?.visibleTitle
                : questionnaireData?.title}
            </span>
          </Grid>
        )}
        <Grid item display="grid">
          <Grid container direction="column" wrap="nowrap" gap="15px">
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  color: "rgb(11, 36, 60)",
                  letterSpacing: "0px",
                  textAlign: "center",
                  fontWeight: 500,
                  whiteSpace: "pre-line",
                  wordBreak: "break-word",
                }}
              >
                {
                  "Le questionnaire a été interrompu par un critère d'exclusion.\nQue souhaitez-vous faire ?"
                }
                {exclusion?.exclusionMessage &&
                  `\nDétails : ${exclusion?.exclusionMessage}`}
              </span>
            </Grid>
            <Grid item display="grid">
              <FullField title="Ajouter un commentaire :" isMandatory={false}>
                <MultilineInput
                  placeholder={
                    exclusion?.exclusionMessage
                      ? exclusion.exclusionMessage
                      : "Aucun."
                  }
                  height="80px"
                  maxLength={200}
                  whiteSpace="pre-line"
                  value={comment}
                  onChange={(e) => setComment(e)}
                />
              </FullField>
            </Grid>
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                alignItems="center"
                columnGap="20px"
                rowGap="10px"
                justifyContent="center"
                wrap="wrap"
              >
                <Grid item display="grid">
                  <GreenButton
                    animation={false}
                    text="Retour"
                    onClick={() => {
                      const questionnaireDataTmp = { ...questionnaireData };
                      for (
                        let i = 0;
                        i < questionnaireDataTmp?.sections?.length;
                        i++
                      ) {
                        for (
                          let j = 0;
                          j < questionnaireDataTmp?.sections[i]?.fields?.length;
                          j++
                        ) {
                          for (
                            let k = 0;
                            k <
                            questionnaireDataTmp?.sections[i]?.fields[j]
                              ?.length;
                            k++
                          ) {
                            if (
                              questionnaireDataTmp?.sections[i]?.fields[j][k]
                                ?.id === stopPopup?.id ||
                              exclusion.conditions
                                .map((y) => y.fieldId)
                                ?.includes(
                                  questionnaireDataTmp?.sections[i]?.fields[j][
                                    k
                                  ]?.id
                                )
                            ) {
                              if (
                                typeof questionnaireData.sections[i].fields[j][
                                  k
                                ].value === "object"
                              ) {
                                questionnaireData.sections[i].fields[j][
                                  k
                                ].value = "";
                              } else {
                                questionnaireData.sections[i].fields[j][
                                  k
                                ].value = "";
                              }
                            }
                          }
                        }
                      }
                      setQuestionnaireData(questionnaireData);
                      setStopPopup(null);
                      setComment("");
                      setExclusion(undefined);
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <GreenButton
                    animation={false}
                    text="Envoyer mes réponses"
                    onClick={() => {
                      const r = formatResponse(
                        questionnaireData,
                        patientUrl,
                        stopPopup?.id,
                        comment
                          ? comment
                          : exclusion?.exclusionMessage
                          ? exclusion.exclusionMessage
                          : "Aucun.",
                        true,
                        true
                      );
                      makeAPIRequest(
                        "post",
                        "/questions/response/create",
                        r,
                        "v3"
                      );
                      setResponse(getFormatedResponse(r));
                      setStopPopup(null);
                      setIsStopped(true);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          display="grid"
          borderTop="1px solid #0B243C"
          borderRight="1px solid #0B243C"
          height="5px"
          borderLeft="1px solid #0B243C"
          paddingTop="5px"
          style={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
        />
        <Grid item display="grid" paddingLeft="15px" paddingRight="15px">
          <Grid container direction="column" wrap="nowrap" gap="15px">
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  color: "rgb(11, 36, 60)",
                  letterSpacing: "0px",
                  textAlign: "center",
                  fontWeight: 500,
                  wordBreak: "break-word",
                }}
              >
                Récapitulatif de la réponse
              </span>
            </Grid>
            <Grid item display="grid">
              <DisplayFormatedResponse
                responseIndex={undefined}
                inputFilter={undefined}
                formatedResponse={(() => {
                  const r = formatResponse(
                    questionnaireData,
                    patientUrl,
                    stopPopup?.id,
                    comment
                      ? comment
                      : exclusion?.exclusionMessage
                      ? exclusion.exclusionMessage
                      : "Aucun.",
                    true,
                    true
                  );
                  return getFormatedResponse(r);
                })()}
                checkboxValue={undefined}
                onCheckboxClick={undefined}
                isOnlyForPDF={true}
                isAfterSended={true}
                {...props}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          display="grid"
          borderBottom="1px solid #0B243C"
          borderRight="1px solid #0B243C"
          height="5px"
          borderLeft="1px solid #0B243C"
          paddingTop="5px"
          style={{
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        />
      </Grid>
    );
  }

  const consentementPhrase = getConsentementPhrase(
    questionnaireData,
    undefined
  );

  const sections: ISection[] =
    getOnlySectionWithGoodCondition(questionnaireData);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      gap={spacingBetweenFormText}
    >
      {(questionnaireData?.title || questionnaireData?.visibleTitle) && (
        <Grid item display="grid">
          <span
            style={{
              fontFamily: "Poppins",
              fontSize: "26px",
              color: "rgb(11, 36, 60)",
              letterSpacing: "0px",
              textAlign: "center",
              fontWeight: 500,
              wordBreak: "break-word",
            }}
          >
            {questionnaireData?.visibleTitle
              ? questionnaireData?.visibleTitle
              : questionnaireData?.title}
          </span>
        </Grid>
      )}
      {questionnaireData?.attachment &&
        questionnaireData?.attachment_name &&
        isPdf(questionnaireData?.attachment_name) && (
          <Grid
            item
            display="grid"
            alignItems="center"
            justifyContent="center"
            style={{
              resize: "vertical",
              maxHeight: "1500px",
              minHeight: "300px",
              overflow: "hidden",
            }}
          >
            <iframe
              style={{
                borderRadius: "20px",
                width: "50vw",
                maxHeight: "1000px",
                minHeight: "300px",
                height: "100%",
                resize: "vertical",
                overflow: "hidden",
              }}
              src={questionnaireData?.attachment}
            />
          </Grid>
        )}
      {questionnaireData?.attachment &&
        questionnaireData?.attachment_name &&
        isImage(questionnaireData?.attachment_name) && (
          <Grid item display="grid" alignItems="center" justifyContent="center">
            <img
              style={{ minWidth: "150px", maxWidth: "50vw" }}
              src={questionnaireData?.attachment}
              alt=""
            />
          </Grid>
        )}
      {isStopped && exclusion && (
        <Grid item display="grid">
          <div
            id="display-text-and-image"
            style={{
              wordBreak: "break-word",
            }}
            dangerouslySetInnerHTML={{
              __html: cleanHTML(
                exclusion?.exclusionMessage
                  ? exclusion.exclusionMessage
                  : "Il s'agit d'un critère d'exclusion, nous clôturons le protocole."
              ),
            }}
          />
        </Grid>
      )}
      {questionnaireData?.stopMessage && isStopped && !exclusion && (
        <Grid item display="grid">
          <div
            id="display-text-and-image"
            style={{
              wordBreak: "break-word",
            }}
            dangerouslySetInnerHTML={{
              __html: cleanHTML(questionnaireData?.stopMessage),
            }}
          />
        </Grid>
      )}
      {messageToDisplay && isSended && !isStopped && (
        <Grid item display="grid">
          <div
            id="display-text-and-image"
            style={{
              wordBreak: "break-word",
            }}
            dangerouslySetInnerHTML={{
              __html: cleanHTML(messageToDisplay),
            }}
          />
        </Grid>
      )}
      {questionnaireData?.description && !isSended && !isStopped && (
        <Grid item display="grid">
          <div
            id="display-text-and-image"
            style={{
              wordBreak: "break-word",
            }}
            dangerouslySetInnerHTML={{
              __html: cleanHTML(questionnaireData?.description),
            }}
          />
        </Grid>
      )}
      {(isSended || exclusion) &&
        response &&
        questionnaireData?.canDownloadResponse && (
          <Grid item display="grid" marginTop="10px">
            <Grid container direction="column" wrap="nowrap" gap="5px">
              <Grid item display="grid">
                <span
                  style={{
                    fontSize: "20px",
                    color: "#0B243C",
                    fontFamily: "Poppins",
                    textAlign: "center",
                  }}
                >{`Récapitulatif de la réponse`}</span>
              </Grid>
              <Grid
                item
                display="grid"
                justifyContent={"start"}
                marginTop="15px"
              >
                <BlueButton
                  label="Exporter sous format PDF"
                  overrideButton={{
                    onClick: () => {
                      createPDFWithElementIds(
                        ["response-formated-for-download"],
                        {
                          onStep: (number) => {
                            return;
                          },
                          setIsDownloadLoading: setDownloadSendedLoading,
                          type: "download",
                          name: questionnaireData?.title
                            ? questionnaireData.title.replaceAll(" ", "_")
                            : "sans_titre",
                          pageWidth: 900,
                        }
                      );
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                display="grid"
                id="response-formated-for-download"
                marginTop="5px"
              >
                <DisplayFormatedResponse
                  responseIndex={undefined}
                  inputFilter={undefined}
                  formatedResponse={response}
                  checkboxValue={undefined}
                  onCheckboxClick={undefined}
                  isOnlyForPDF={true}
                  isAfterSended={true}
                  {...props}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      {consentementPhrase && !isSended && !isStopped && (
        <Grid item display={"grid"} marginTop="10px">
          <Grid container direction="column" wrap="nowrap" gap="20px">
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  color: "rgb(35, 89, 93)",
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                Consentement du patient
              </span>
            </Grid>
            <Grid item display="grid">
              <span
                style={{
                  fontSize: "12px",
                  color: "#0B243C",
                  fontFamily: "Arial",
                  fontStyle: "italic",
                  lineHeight: "18px",
                }}
              >
                {consentementPhrase}
              </span>
            </Grid>
            <Grid item display="grid">
              <Grid
                container
                id={"field_consent_choice"}
                border="0px solid transparent"
                style={{
                  width: "fit-content",
                }}
                borderRadius="10px"
              >
                <FullField
                  isQuestionnaire={true}
                  title={
                    "En cochant cette case, je consens au traitement de mes données personnelles selon les modalités décrites ci-dessus"
                  }
                  isMandatory={true}
                  hideAsterics={false}
                >
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    display="flex"
                    rowGap="10px"
                    columnGap="30px"
                    paddingTop="5px"
                  >
                    {[
                      {
                        label: "Oui",
                        value: "Oui",
                      },
                      {
                        label: "Non",
                        value: "Non",
                      },
                    ].map((option, index) => (
                      <Grid item key={index} display="grid">
                        <CheckBoxField
                          text={option?.label}
                          value={
                            (questionnaireData?.isPatientConsentant === true &&
                              option?.value === "Oui") ||
                            (questionnaireData?.isPatientConsentant === false &&
                              option?.value === "Non")
                          }
                          onClick={() => {
                            setQuestionnaireData((x) => {
                              return {
                                ...x,
                                isPatientConsentant:
                                  option?.value === "Oui" ? true : false,
                              };
                            });
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FullField>
              </Grid>
            </Grid>
            <Grid item display="grid">
              <div
                style={{
                  borderTop: "2px solid #E6EBF0",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {questionnaireData?.displayMode === "page" && !isSended && !isStopped && (
        <Grid item display="grid">
          <Grid container wrap="nowrap" direction="column">
            <Grid item display="grid">
              <DisplayPagesMenu
                actualPage={actualPage}
                setActualPage={setActualPage}
                sectionTitles={questionnaireData?.sections?.map(
                  (section) => section?.title
                )}
              />
            </Grid>
            <Grid item display="grid" paddingTop="30px">
              <DisplaySection
                questionnaireData={questionnaireData}
                setQuestionnaireData={setQuestionnaireData}
                sectionIndex={actualPage}
                displayMandatory={displayMandatory}
                setIsStopped={setIsStopped}
                setStopPopup={setStopPopup}
                setExclusion={setExclusion}
              />
            </Grid>
            <Grid item display="grid" paddingTop="25px">
              <DisplayPagination
                setResponse={setResponse}
                comment={comment}
                stopPopup={stopPopup}
                isPreview={isPreview}
                questionnaireData={questionnaireData}
                setIsSended={setIsSended}
                displayMandatory={displayMandatory}
                setDisplayMandatory={setDisplayMandatory}
                section={questionnaireData?.sections[actualPage]}
                setActualPage={setActualPage}
                actualPage={actualPage}
                length={questionnaireData?.sections?.length}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {questionnaireData?.displayMode !== "page" &&
        sections?.length > 0 &&
        !isSended &&
        !isStopped && (
          <Grid item display="grid">
            <Grid container wrap="nowrap" direction="column" gap="15px">
              {sections?.map((section, sectionIndex) => {
                if (!section) {
                  return <></>;
                }
                return (
                  <Grid item key={section?.id} display="grid">
                    <Grid container direction="column" wrap="nowrap" gap="10px">
                      {sectionIndex !== 0 && (
                        <Grid item display="grid" marginTop="15px">
                          <div
                            style={{
                              borderTop: "2px solid #E6EBF0",
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item display="grid">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "18px",
                            color: "#23595d",
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          {section?.title}
                        </span>
                      </Grid>
                      <Grid item display="grid">
                        <DisplaySection
                          questionnaireData={questionnaireData}
                          setQuestionnaireData={setQuestionnaireData}
                          sectionIndex={sectionIndex}
                          setIsStopped={setIsStopped}
                          setStopPopup={setStopPopup}
                          setExclusion={setExclusion}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      {questionnaireData?.textRGPD && !isSended && !isStopped && (
        <div
          style={{
            wordBreak: "break-word",
          }}
          dangerouslySetInnerHTML={{
            __html: cleanHTML(questionnaireData?.textRGPD),
          }}
        />
      )}
      {questionnaireData?.userSettings &&
        questionnaireData?.userSettings?.displayTextAtEnd === true &&
        questionnaireData?.userSettings?.textToDisplayAtEnd && (
          <Grid item display="grid" marginTop="10px">
            <div
              id="display-text-and-image"
              style={{
                wordBreak: "break-word",
              }}
              dangerouslySetInnerHTML={{
                __html: cleanHTML(
                  questionnaireData?.userSettings?.textToDisplayAtEnd
                ),
              }}
            />
          </Grid>
        )}
      {questionnaireData?.sections?.length > 0 &&
        !isSended &&
        !isStopped &&
        questionnaireData?.displayMode !== "page" && (
          <Grid item display="grid">
            <DisplayButtons
              {...props}
              setIsSended={setIsSended}
              setResponse={setResponse}
              stopPopup={stopPopup}
              comment={comment}
              isSended={isSended}
            />
          </Grid>
        )}
    </Grid>
  );
};

export default GenerateQuestionnaire;
