import FullField from "../../../Fields/FullField";
import * as React from "react";
import MySelect from "../../../Library/Select/MySelect";
import HandleErrors from "../../../Library/Errors/HandleErrors";
import { IField } from "../../../../types/FormTypes";

const DisplayMedecinsTraitants: React.FC<any> = (props) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const field: IField = props.field;
  const {
    sectionIndex,
    justifyContent,
    fieldIndex,
    disableError,
    fieldRowIndex,
    questionnaireData,
    setQuestionnaireData,
  } = props;

  interface IOption {
    label?: string;
    value?: string;
  }

  const options = ((): IOption[] => {
    const options: IOption[] = [];
    if (!field?.medecinsTraitantInfos?.medecinTraitantsList) {
      return options;
    }
    if (field?.medecinsTraitantInfos?.medecinTraitantsList?.length == 0) {
      return options;
    }

    for (const medecin of field?.medecinsTraitantInfos.medecinTraitantsList) {
      if (
        medecin?.medecinInfos?.mail &&
        medecin?.medecinInfos?.nom &&
        medecin?.medecinInfos?.prenom
      ) {
        options.push({
          value: medecin?.medecinInfos?.mail,
          label: `${medecin?.medecinInfos?.nom} ${medecin?.medecinInfos?.prenom}`,
        });
      }
    }
    return options;
  })();
  const optionSelected = options
    ? options.find((x) => x?.value === field?.value)
    : undefined;

  return (
    <FullField
      isQuestionnaire={true}
      title={field?.title}
      isMandatory={field?.isMandatory}
      justifyContent={justifyContent}
      hideAsterics={field?.hideAsterics ? true : false}
    >
      <HandleErrors
        isError={isError && !disableError}
        isMandatory={field?.isMandatory}
        errorMessage={errorMessage}
      >
        <MySelect
          id={"field" + field?.id}
          isError={isError && !disableError}
          value={optionSelected ? optionSelected : undefined}
          options={options}
          placeholder=""
          onChange={(e) => {
            setQuestionnaireData((x) => {
              const before = {...x};
              if (x?.sections) {
                for (let i = 0; i < x.sections.length; i++) {
                  if (x?.sections[i]) {
                    for (let j = 0; j < x?.sections?.[i]?.fields?.length; j++) {
                      if (x?.sections?.[i]?.fields[j]) {
                        for (let k = 0; k < x?.sections?.[i]?.fields[j]?.length; k++) {
                          if (x?.sections?.[i]?.fields[j][k]) {
                            if (x.sections[i].fields[j][k].id == field?.id) {
                              x.sections[i].fields[j][k].value = e?.value;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              return before;
            });
          }}
          onFocus={(e) => {
            if (!field?.isMandatory) return;
            if (isError && !disableError) {
              setErrorMessage("");
              setIsError(false);
            }
          }}
          onBlur={(e) => {
            if (!field?.isMandatory) return;
            if (
              !questionnaireData.sections[sectionIndex].fields[fieldRowIndex][
                fieldIndex
              ].value
            ) {
              if (!isError && !disableError) {
                setErrorMessage("Ce champ est obligatoire.");
                setIsError(true);
              }
            } else {
              if (isError && !disableError) {
                setErrorMessage("");
                setIsError(false);
              }
            }
          }}
        />
      </HandleErrors>
    </FullField>
  );
};

export default DisplayMedecinsTraitants;
