import {
  ArchiveOutlined,
  AttachEmailOutlined,
  ClearRounded,
  DeleteOutline,
  DownloadOutlined,
  OpenInNewRounded,
  SearchOutlined,
} from "@mui/icons-material";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import DisplayTitle from "./DisplayTitle";
import TextInput from "../../Library/Inputs/TextInput";
import FullField from "../../Fields/FullField";
import OrangeCheckBox from "../../Fields/CheckBox/OrangeCheckBox";
import DisplayFormatedResponse, {
  displayPatientForResponse,
  formatDateForResponse,
} from "./DisplayFormatedResponse";
import formatValue from "./formatValue";
import MySelect from "../../Library/Select/MySelect";
import ConfigResponsePerPage from "./ConfigResponsePerPage";
import ResponseNavigation from "./ResponseNavigation";
import FormPopup from "../../FormPopup/FormPopup";
import FormPopupHeader from "../../FormPopup/FormPopupHeader";
import OrangeButton from "../../Library/Buttons/OrangeButton/OrangeButton";
import exportPdf from "../../../utils/exportPdf";
import QuestionnaireUtils from "../QuestionnaireUtils";
import AddButton from "../../Library/Buttons/AddButton/AddButton";
import { nanoid } from "nanoid";
import useQuery from "../../../utils/hooks/useQuery";
import { getActualTime } from "../../NavbarComponent";
import useAppSelector from "../../../redux/useAppSelector";
import {
  GenericFromPopup,
  GenericSendButton,
} from "../../../pages/ChatbotLexPage";
import { MedimailSearch } from "../SendResponseMedimailPopup";
import MultilineInput from "../../Library/Inputs/MultilineInput";
import makeAPIRequest from "../../../utils/makeAPIRequest";
import { useOpenSnackbar } from "../../../pages/CreateOrEditChatbotLexPage";
import { GraphiqueAraignee } from "../../../pages/QuestionnairePage";
import { IForm } from "../../../types/FormTypes";
import CancellablePopup from "../../Library/Popups/CancellablePopup";

export const getPatientNameById = (id, patientList) => {
  if (!id) return "Anonyme";
  const patient = patientList?.find((e) => e?.id === id);
  return patient?.lastname + " " + patient?.firstname;
};

export const checkIsFSUSForm = (form: IForm) => {
  let countValidField = 0;
  form.sections.forEach((section) => {
    section.fields.forEach((fieldRow) => {
      fieldRow.forEach((field) => {
        if (field?.option?.length === 5) {
          countValidField++;
        }
      });
    });
  });
  if (countValidField === 10) {
    return true;
  }
  return false;
};

const getAnonymPatientNameById = (id, patientList) => {
  if (!id) return "Anonyme";
  const patient = patientList?.find((e) => e?.id === id);
  return (
    patient?.lastname?.substring(0, 3) + patient?.id + " " + patient?.firstname
  );
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp);

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();

  let hours = date.getHours().toString();
  if (hours.length === 1) hours = `0${hours}`;

  let minutes = date.getMinutes().toString();
  if (minutes.length === 1) minutes = `0${minutes}`;

  return yyyy + "/" + mm + "/" + dd + " à " + hours + "h" + minutes;
};

const calculIndex = (result, firstLine) => {
  const index = firstLine.findIndex((res) => res === result);

  if (index === -1) return null;
  return index;
};

export const getDateEnglishFromTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);

  const dateString = date
    .toLocaleString("en-US", {
      timeZone: "Europe/Paris",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
      timeZoneName: "short",
    })
    ?.replaceAll(",", "");
  if (!dateString) {
    return "";
  }
  const datePart = dateString?.split(" ")?.[0];
  if (!datePart) {
    return "";
  }
  const splitedDatePart = datePart.split("/");
  if (splitedDatePart?.length !== 3) {
    return "";
  }
  if (splitedDatePart[0].length === 4) {
    return dateString;
  }
  // 09-03-2023 => 2023-03-09
  return `${splitedDatePart[2]}/${splitedDatePart[0]}/${
    splitedDatePart[1]
  }${dateString.substring(datePart.length, dateString.length)}`;
};

export const getHourFromTimeStamp = (timestamp: number) => {
  const date = getActualTime(timestamp);

  return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

const getCorrespondancesFieldsTitle = (
  graphs: GraphiqueAraignee[],
  fieldId: string
): string => {
  const result = [];
  if (!Array.isArray(graphs) || graphs?.length == 0) {
    return "";
  }
  if (!fieldId) {
    return "";
  }
  graphs.forEach((graph) => {
    if (Array.isArray(graph?.fields)) {
      graph.fields.forEach((field) => {
        if (
          field?.entitled &&
          field?.fieldId === fieldId &&
          !result.includes(field.entitled)
        ) {
          result.push(field.entitled);
        }
      });
    }
  });
  return result.length > 0 ? "\r\n" + result.join("\r\n") : "";
};

const exportResponsesCsv = (data, patientList, questionnaireData) => {
  const lines = [];
  const addScoreFSUS = checkIsFSUSForm(questionnaireData);
  lines.push(["Date", "Heure", "Numéro d'anonymat"]);
  if (addScoreFSUS) {
    lines[0].push("Score total F-SUS");
  }
  for (const response of data) {
    if (typeof response?.sections !== "object") continue;
    for (const sectionTitle of Object.keys(response?.sections)) {
      for (const field of response?.sections?.[sectionTitle]) {
        const fieldId = field?.id;
        const fieldTitle = field?.fieldTitle;
        const correspondances = getCorrespondancesFieldsTitle(
          questionnaireData?.graphs,
          fieldId
        );
        const result = `${sectionTitle}${
          sectionTitle ? " " : ""
        }${fieldTitle}${correspondances}`;
        if (!lines[0].includes(result)) lines[0].push(result);
      }
    }
  }

  for (const response of data) {
    if (typeof response?.sections !== "object") continue;
    lines.push([]);
    const index = lines.length - 1;
    for (let i = 0; i < lines[0].length; i++) lines[index].push("");
    lines[index][0] = getDateEnglishFromTimestamp(response?.created);
    lines[index][1] = getHourFromTimeStamp(response?.created);
    lines[index][2] = response?.patientId ? response?.patientId : "Anonyme";
    if (addScoreFSUS) {
      lines[index][3] = response?.scoreTotalFSUS
        ? `${response?.scoreTotalFSUS}`
        : "";
    }
    for (const sectionTitle of Object.keys(response?.sections)) {
      for (const field of response?.sections?.[sectionTitle]) {
        const fieldTitle = field?.fieldTitle;
        const fieldId = field?.id;
        const correspondances = getCorrespondancesFieldsTitle(
          questionnaireData?.graphs,
          fieldId
        );
        const result = `${sectionTitle}${
          sectionTitle ? " " : ""
        }${fieldTitle}${correspondances}`;
        const newIndex = calculIndex(result, lines[0]);
        if (newIndex) lines[index][newIndex] = formatValue(field?.value);
      }
    }
  }

  console.log(lines);
  let csv = "";
  for (let i = 0; i < lines.length; i++) {
    for (let j = 0; j < lines[i].length; j++) {
      csv += `"${lines[i][j]?.replaceAll("\n", " ")?.replaceAll('"', '""')}"`;
      if (j === lines[i].length - 1) {
        csv += "\n";
      } else {
        csv += ";";
      }
    }
  }

  const universalBOM = "\uFEFF";
  const element = window.document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv; charset=utf-8," + encodeURIComponent(universalBOM + csv)
  );
  element.setAttribute(
    "download",
    `${questionnaireData?.title}-reponses.csv`.replaceAll(" ", "-")
  );
  window.document.body.appendChild(element);
  element.click();

  return csv;
};

const DisplayHowManyResponse: React.FC<any> = (props) => {
  const { filteredResponses } = props;

  const nbResponse = filteredResponses?.length;

  return (
    <span
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#0B243C",
      }}
    >
      {!nbResponse || nbResponse === 0
        ? "Aucune réponse trouvée"
        : `${nbResponse} réponse${nbResponse > 1 ? "s" : ""} trouvée${
            nbResponse > 1 ? "s" : ""
          }`}
    </span>
  );
};

const DisplayResponses: React.FC<any> = (props) => {
  const { responses, patientList, setResponses } = props;

  type FormatType = "csv" | "pdf";
  // Pages
  const [sectionsToHide, setSectionsToHide] = React.useState<number[]>([]);
  const [responsesPerPage, setResponsesPerPage] = React.useState(5);
  const [actualPage, setActualPage] = React.useState(0);
  const [pages, setPages] = React.useState([]);

  const [patientSearched, setPatientSearched] = React.useState(null);
  const [responseSearched, setResponseSearched] = React.useState(null);
  const [formatedResponses, setFormatedResponses] = React.useState([]);
  const [filteredResponses, setFilteredResponses] = React.useState([]);
  const [hideEmptyFields, setHideEmptyFields] = React.useState(true);
  const [displayAnonymOnly, setDisplayAnonymOnly] = React.useState(false);
  const [displayRegisteredOnly, setDisplayRegisteredOnly] =
    React.useState(false);
  const [displayArchived, setDisplayArchived] = React.useState(false);
  const [sortingByRecent, setSortingByRecent] = React.useState(true);
  const [inputFilter, setInputFilter] = React.useState("");
  const [isExport, setIsExport] = React.useState(false);
  const [myListBase64, setMyListBase64] = React.useState([]);
  const [type, setType] = React.useState<FormatType>("csv");
  const fieldOptions = QuestionnaireUtils.getFieldListOptions(
    props?.questionnaireData
  );
  const [startDate, setStartDate] = React.useState<string>(undefined);
  const [endDate, setEndDate] = React.useState<string>(undefined);
  const patientIdQuery = useQuery().get("p");
  const responseIdQuery = useQuery().get("r");
  const [selectedResponses, setSelectedResponses] = React.useState<any[]>([]);
  const user = useAppSelector((x) => x.userReducer.user);
  const [sendResponsesOpen, setSendResponsesOpen] =
    React.useState<boolean>(false);
  const [destinatairesMail, setDestinatairesMail] = React.useState<any[]>([]);
  const [emailSubject, setEmailSubject] = React.useState<string>("");
  const [content, setContent] = React.useState<string>("");
  const snackbarUse = useOpenSnackbar();
  const [isPDFExportAllLoading, setIsPDFExportAllLoading] =
    React.useState<boolean>(false);
  const [downloadPDFLoading, setDownloadPDFLoading] =
    React.useState<boolean>(false);
  const [seeAttachmentPDFLoading, setSeeAttachmentPDFLoading] =
    React.useState<boolean>(false);
  const [seeAttachmentPDFLoadingStep, setSeeAttachmentPDFLoadingStep] =
    React.useState<number>(0);
  const [deleteMassLoading, setDeleteMassLoading] = React.useState(false);
  const [downloadPDFLoadingStep, setDownloadPDFLoadingStep] =
    React.useState<number>(0);
  const [PDFExportAllStep, setPDFExportAllStep] = React.useState<number>(0);

  const [medimailLoading, setMedimailLoading] = React.useState<boolean>(false);
  const [sendResponseMedimailLoading, setSendResponseMedimailLoading] =
    React.useState<boolean>(false);
  const [sendResponseMedimailStep, setSendResponseMedimailStep] =
    React.useState<number>(0);

  React.useEffect(() => {
    if (patientList) {
      if (patientIdQuery) {
        setPatientSearched({
          label: getPatientNameById(patientIdQuery, patientList),
          value: patientIdQuery,
        });
      } else {
        setPatientSearched(null);
      }
    }
  }, [patientIdQuery, patientList]);

  React.useEffect(() => {
    if (responseIdQuery) {
      setResponseSearched({ value: responseIdQuery, label: responseIdQuery });
    } else {
      setResponseSearched(null);
    }
  }, [responseIdQuery]);

  React.useEffect(() => {
    if (myListBase64?.length == 0) {
      return;
    }
    if (destinatairesMail?.length == 0) {
      return;
    }
    const toSend = {
      subject: emailSubject,
      content: content,
      destinataires: destinatairesMail,
      pdfList: myListBase64,
    };
    makeAPIRequest(
      "post",
      "/questions/response/send_by_mail_medimail",
      toSend,
      "v3"
    )
      .then((res) => {
        snackbarUse.success("L'e-mail a été envoyé avec succès.", res);
      })
      .catch((err) => {
        snackbarUse.error("L'email n'a pas pu être envoyé.", err);
      })
      .finally(() => {
        setSendResponsesOpen(false);
        setMedimailLoading(false);
        setMyListBase64([]);
        setDestinatairesMail([]);
        setEmailSubject("");
        setContent("");
        setSelectedResponses([]);
      });
  }, [myListBase64, destinatairesMail, content, emailSubject]);

  const [conditionsType, setConditionsType] = React.useState<"or" | "and">(
    "and"
  );

  const conditionsOptions = [
    { value: "and", label: "Toutes les conditions sont vraies" },
    { value: "or", label: "Au moins une condition est vraie" },
  ];

  const [
    popupDeleteDefinitivelyResponses,
    setPopupDeleteDefinitivelyResponses,
  ] = React.useState<any[]>(undefined);
  const [filterByTextSearched, setFilterByTextSearched] = React.useState<
    Array<{
      id: string;
      question: string | undefined;
      textSearched: string | undefined;
    }>
  >([
    {
      id: nanoid(),
      question: undefined,
      textSearched: undefined,
    },
  ]);

  const isNotFiltered = (fields) => {
    if (!fields) {
      return false;
    }
    const listFieldsIdFiltered = filterByTextSearched.map((x) => x.question);
    console.log("List fields id filtered");
    console.log(listFieldsIdFiltered);
    if (
      filterByTextSearched.length === 1 &&
      !filterByTextSearched[0].textSearched
    ) {
      return true;
    }
    if (conditionsType === "and") {
      for (const f of filterByTextSearched.filter(
        (x) => !x.question && x.textSearched
      )) {
        let invalid = true;
        for (const field of fields) {
          if (
            formatValue(field?.value)
              ?.toLowerCase()
              .includes(f.textSearched?.toLowerCase())
          ) {
            invalid = false;
            break;
          }
        }
        if (invalid) {
          return false;
        }
      }
      for (const field of fields) {
        if (!listFieldsIdFiltered.includes(field?.id)) {
          continue;
        }
        for (const f of filterByTextSearched.filter(
          (x) => x.question === field?.id
        )) {
          if (!f.textSearched) {
            continue;
          }
          if (
            !formatValue(field?.value)
              ?.toLowerCase()
              .includes(f.textSearched?.toLowerCase())
          ) {
            return false;
          }
        }
      }
      return true;
    }
    if (conditionsType === "or") {
      for (const f of filterByTextSearched.filter(
        (x) => !x.question && x.textSearched
      )) {
        for (const field of fields) {
          if (
            formatValue(field?.value)
              ?.toLowerCase()
              .includes(f.textSearched?.toLowerCase())
          ) {
            return true;
          }
        }
      }
      for (const field of fields) {
        if (!listFieldsIdFiltered.includes(field?.id)) {
          continue;
        }
        for (const f of filterByTextSearched.filter(
          (x) => x.question === field?.id
        )) {
          if (!f.textSearched) {
            continue;
          }
          if (
            formatValue(field?.value)
              ?.toLowerCase()
              .includes(f.textSearched?.toLowerCase())
          ) {
            return true;
          }
        }
      }
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    const newFilteredResponses = [...responses];
    setFilteredResponses(
      newFilteredResponses
        ?.filter((response) => {
          if (startDate) {
            const startTimestamp = Date.parse(startDate + "T" + "00" + ":00");
            if (response?.created > startTimestamp) {
              if (endDate) {
                const endDateTimestamp = Date.parse(
                  endDate + "T" + "23" + ":59"
                );
                if (response?.created < endDateTimestamp) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            }
            return false;
          }
          return true;
        })
        ?.filter((response) => isNotFiltered(response?.fields))
        ?.filter((response) =>
          displayArchived ? response.isArchived : !response?.isArchived
        )
        ?.filter((response) => (displayAnonymOnly ? !response.patientId : true))
        ?.filter((response) =>
          displayRegisteredOnly ? response.patientId : true
        )
        ?.filter((response) =>
          patientSearched?.value
            ? response.patientId === patientSearched?.value
            : true
        )
        ?.filter((response) =>
          responseSearched?.value
            ? response.id === responseSearched?.value
            : true
        )
        ?.sort((a, b) =>
          sortingByRecent ? b?.created - a?.created : a?.created - b?.created
        )
    );
  }, [
    responses,
    conditionsType,
    filterByTextSearched,
    displayArchived,
    sortingByRecent,
    displayAnonymOnly,
    displayRegisteredOnly,
    responseSearched,
    patientSearched,
    startDate,
    endDate,
  ]);

  React.useEffect(() => {
    const formatedResponsesTmp = [];
    if (filteredResponses) {
      for (const filteredResponse of filteredResponses) {
        const newFilteredResponse = { ...filteredResponse };
        for (const field of filteredResponse?.fields) {
          if (
            formatValue(field?.value) === "Vide" ||
            !formatValue(field?.value)
          ) {
            if (hideEmptyFields) continue;
          }
          if (!newFilteredResponse?.sections) newFilteredResponse.sections = {};
          if (!newFilteredResponse.sections[field?.sectionTitle])
            newFilteredResponse.sections[field?.sectionTitle] = [];
          newFilteredResponse.sections[field?.sectionTitle].push(field);
        }
        delete newFilteredResponse.fields;
        formatedResponsesTmp.push(newFilteredResponse);
      }
      setFormatedResponses(formatedResponsesTmp);
    }
  }, [filteredResponses, hideEmptyFields]);

  // Update pages after responses filtered change
  React.useEffect(() => {
    const newPages = [];
    let index = 0;
    for (const message of formatedResponses) {
      if (index % responsesPerPage === 0) {
        newPages.push([]);
      }
      const lastIndex = newPages.length - 1;
      if (newPages[lastIndex]) {
        newPages[lastIndex].push(message);
      }
      index++;
    }
    setPages(newPages);
  }, [formatedResponses, responsesPerPage]);

  // Update actual page after pages change
  React.useEffect(() => {
    if (actualPage === 0) return;
    if (actualPage > pages.length - 1) {
      setActualPage(pages.length - 1);
      return;
    }
    if (actualPage < 0) {
      setActualPage(0);
      return;
    }
  }, [actualPage, pages]);

  const isValidSubmit = () => {
    return true;
  };

  const selectedCanBeDeleted =
    selectedResponses.filter((x) => x?.isArchived === true).length ===
    selectedResponses.length;

  return (
    <>
      {popupDeleteDefinitivelyResponses && (
        <CancellablePopup
          cancelButtonText="Non"
          confirmButtonText="Oui"
          firstTitle="Supprimer"
          secondTitle="mes réponses"
          isLoading={deleteMassLoading}
          messages={[
            popupDeleteDefinitivelyResponses?.length === 1
              ? `Êtes-vous sûr de vouloir supprimer la réponse ci-dessous ?`
              : `Êtes-vous sûr de vouloir supprimer les ${popupDeleteDefinitivelyResponses.length} réponses ci-dessous ?`,
            ...(() => {
              return popupDeleteDefinitivelyResponses.map((resp) => {
                return `${formatDateForResponse(
                  resp
                )} - ${displayPatientForResponse(resp, patientList)}`;
              });
            })(),
          ]}
          onClose={() => {
            setPopupDeleteDefinitivelyResponses(undefined);
          }}
          onCancel={() => {
            setPopupDeleteDefinitivelyResponses(undefined);
          }}
          onConfirm={() => {
            setDeleteMassLoading(true);
            makeAPIRequest(
              "post",
              "/questions/response/delete_mass",
              {
                ids: selectedResponses?.map((x) => x?.id),
              },
              "v3"
            )
              .then((res) => {
                setResponses((state) => {
                  return state.filter((r) => {
                    if (selectedResponses.find((y) => y?.id == r?.id)) {
                      return false;
                    }
                    return true;
                  });
                });
                setSelectedResponses([]);
                setPopupDeleteDefinitivelyResponses(undefined);
                snackbarUse.success("Vos réponses ont été supprimées.", res);
              })
              .catch((err) => {
                snackbarUse.error(
                  "Vos réponses n'ont pas pu être supprimées.",
                  err
                );
              })
              .finally(() => setDeleteMassLoading(false));
          }}
        />
      )}
      {sendResponsesOpen && (
        <GenericFromPopup
          onClose={() => setSendResponsesOpen(false)}
          title={{ leftPart: "Envoyer", rightPart: "les réponses" }}
          subtitle={`${selectedResponses.length} réponse${
            selectedResponses.length > 1 ? "s" : ""
          } sélectionnée${selectedResponses.length > 1 ? "s" : ""}`}
        >
          <Grid
            container
            direction="column"
            padding="5px"
            wrap="nowrap"
            gap="15px"
          >
            <Grid
              item
              display="grid"
              justifyContent={seeAttachmentPDFLoading ? "center" : "flex-end"}
            >
              <>
                {seeAttachmentPDFLoading && (
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    justifyContent="center"
                    alignItems="center"
                    gap="10px"
                  >
                    <Grid item display="grid">
                      <CircularProgress />
                    </Grid>
                    <Grid item display="grid">
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#0B243C",
                        }}
                      >
                        {`Création du PDF en cours... ${
                          seeAttachmentPDFLoadingStep + 1
                        }/${selectedResponses.length}`}
                      </span>
                    </Grid>
                  </Grid>
                )}
                {!seeAttachmentPDFLoading && (
                  <Tooltip title="Voir la pièce jointe">
                    <IconButton
                      disabled={seeAttachmentPDFLoading ? true : false}
                      style={{
                        margin: "0",
                        padding: "0",
                        cursor: seeAttachmentPDFLoading
                          ? "not-allowed"
                          : "pointer",
                      }}
                    >
                      <OpenInNewRounded
                        fontSize="medium"
                        onClick={async () => {
                          setSeeAttachmentPDFLoading(true);
                          setSeeAttachmentPDFLoadingStep(0);
                          await exportPdf(
                            (number) => {
                              setSeeAttachmentPDFLoadingStep(number);
                            },
                            selectedResponses,
                            patientList,
                            props?.questionnaireData,
                            "open"
                          );
                          setSeeAttachmentPDFLoading(false);
                          setSeeAttachmentPDFLoadingStep(0);
                        }}
                        style={{
                          color: "rgb(11, 36, 60)",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            </Grid>
            <Grid item display="grid">
              <MedimailSearch
                onUpdateSelected={(selected) => {
                  setDestinatairesMail(selected.map((x) => x.mail));
                }}
              />
            </Grid>
            <Grid item display="grid">
              <FullField isMandatory={true} title={`Objet du mail`}>
                <TextInput
                  id="send-response-subject"
                  onChange={(e) => setEmailSubject(e?.target?.value)}
                  value={emailSubject}
                />
              </FullField>
            </Grid>
            <Grid item display="grid">
              <FullField isMandatory={true} title={`Contenu du mail`}>
                <Grid container direction="column" wrap="nowrap">
                  <Grid item display="grid" paddingTop="5px">
                    <MultilineInput
                      id="send-response-content"
                      maxLength={50000}
                      onChange={(e) => setContent(e)}
                      value={content}
                      height={"80px"}
                    />
                  </Grid>
                </Grid>
              </FullField>
            </Grid>
            <Grid item display="grid">
              {sendResponseMedimailLoading || medimailLoading ? (
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  justifyContent="center"
                  alignItems="center"
                  gap="10px"
                >
                  <Grid item display="grid">
                    <CircularProgress />
                  </Grid>
                  <Grid item display="grid">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        color: "#0B243C",
                      }}
                    >
                      {medimailLoading
                        ? `En attente de Medimail...`
                        : `Création et envoi du PDF en cours... ${
                            sendResponseMedimailStep + 1 >
                            selectedResponses.length
                              ? selectedResponses.length
                              : sendResponseMedimailStep + 1
                          }/${selectedResponses.length}`}
                    </span>
                  </Grid>
                </Grid>
              ) : (
                <GenericSendButton
                  invalidConditions={{
                    emptyDestinataire:
                      !destinatairesMail || destinatairesMail?.length === 0,
                    emptySubject: !emailSubject,
                    emptyContent: !content,
                  }}
                  errorMessages={{
                    emptyDestinataire:
                      "Il faut ajouter au minimum un destinataire",
                    emptyContent: "Le contenu du mail ne peut pas être vide",
                    emptySubject: "L'objet du mail ne peut pas être vide",
                    "": "",
                  }}
                  errorSelector={{
                    emptyDestinataire: "#send-response-destinataires",
                    emptySubject: "#send-response-subject",
                    emptyContent: "#send-response-content",
                    "": "",
                  }}
                  onSendClick={async () => {
                    setSendResponseMedimailLoading(true);
                    setSendResponseMedimailStep(0);
                    const result = await exportPdf(
                      (number) => {
                        setSendResponseMedimailStep(number);
                      },
                      selectedResponses,
                      patientList,
                      props?.questionnaireData,
                      "get-base64"
                    );
                    result.pdfGenerated.getBase64((data) => {
                      setMedimailLoading(true);
                      setSendResponseMedimailLoading(false);
                      setSendResponseMedimailStep(0);
                      setMyListBase64((l) => {
                        return [...l, { content: data, name: result.name }];
                      });
                    });
                  }}
                />
              )}
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      <div
        style={{
          position: "fixed",
          backgroundColor: "#0b243c",
          bottom: 50,
          right: 50,
          zIndex: 3,
          borderRadius: 50,
          boxShadow: "2px 6px 10px #0F213467",
          opacity: selectedResponses.length > 0 ? 1 : 0,
          transform:
            selectedResponses.length > 0 ? "translateY(0)" : "translateY(100%)",
          transition: "opacity 0.5s, transform 0.5s",
        }}
      >
        <>
          <div
            style={{
              fontSize: "16px",
              fontFamily: "Poppins",
              fontWeight: "bold",
              borderRadius: "30px",
              width: "25px",
              height: "25px",
              backgroundColor: "#ffa500",
              position: "absolute",
              right: -10,
              top: -3,
              color: "#0b243c",
              zIndex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>{selectedResponses.length}</span>
          </div>
          <Tooltip
            title={
              selectedCanBeDeleted
                ? "Supprimer définitivement les réponses"
                : "Archiver les réponses"
            }
          >
            <IconButton
              disabled={false}
              style={{
                margin: "0",
                padding: 10,
                cursor: "pointer",
              }}
              onClick={
                selectedCanBeDeleted
                  ? () => {
                      setPopupDeleteDefinitivelyResponses([
                        ...selectedResponses,
                      ]);
                    }
                  : () => {
                      makeAPIRequest(
                        "post",
                        "/questions/response/archive_mass",
                        {
                          ids: selectedResponses?.map((x) => x?.id),
                        },
                        "v3"
                      )
                        .then((res) => {
                          setResponses((state) => {
                            return state.map((r) => {
                              if (
                                selectedResponses.find((y) => y?.id == r?.id)
                              ) {
                                return {
                                  ...r,
                                  isArchived: true,
                                };
                              }
                              return r;
                            });
                          });
                          setSelectedResponses([]);
                          snackbarUse.success(
                            "Vos réponses ont été archivées.",
                            res
                          );
                        })
                        .catch((err) => {
                          snackbarUse.error(
                            "Vos réponses n'ont pas pu être archivées.",
                            err
                          );
                        });
                    }
              }
            >
              {selectedCanBeDeleted ? (
                <DeleteOutline fontSize="large" style={{ color: "#ffa500" }} />
              ) : (
                <ArchiveOutlined
                  fontSize="large"
                  style={{ color: "#ffa500" }}
                />
              )}
            </IconButton>
          </Tooltip>
        </>
      </div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#0b243c",
          bottom: 50,
          right: 120,
          zIndex: 3,
          borderRadius: 50,
          boxShadow: "2px 6px 10px #0F213467",
          opacity: selectedResponses.length > 0 ? 1 : 0,
          transform:
            selectedResponses.length > 0 ? "translateY(0)" : "translateY(100%)",
          transition: "opacity 0.5s, transform 0.5s",
        }}
      >
        <>
          <div
            style={{
              fontSize: "16px",
              fontFamily: "Poppins",
              fontWeight: "bold",
              borderRadius: "30px",
              width: "25px",
              height: "25px",
              backgroundColor: "#ffa500",
              position: "absolute",
              right: -10,
              top: -3,
              color: "#0b243c",
              zIndex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>{selectedResponses.length}</span>
          </div>
          <Tooltip title="Télécharger les réponses (PDF)">
            <IconButton
              disabled={downloadPDFLoading ? true : false}
              style={{
                margin: "0",
                padding: 10,
                cursor: downloadPDFLoading ? "not-allowed" : "pointer",
              }}
              onClick={async () => {
                snackbarUse.success(
                  `Le téléchargement des réponses est en cours de préparation...`
                );
                setDownloadPDFLoading(true);
                setDownloadPDFLoadingStep(0);
                await exportPdf(
                  (number) => {
                    setDownloadPDFLoadingStep(number);
                  },
                  selectedResponses,
                  patientList,
                  props?.questionnaireData
                );
                setSelectedResponses([]);
                setDownloadPDFLoadingStep(0);
                setDownloadPDFLoading(false);
                snackbarUse.success("Vos réponses ont été téléchargées.");
              }}
            >
              <DownloadOutlined fontSize="large" style={{ color: "#ffa500" }} />
            </IconButton>
          </Tooltip>
        </>
      </div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#0b243c",
          bottom: 50,
          right: 190,
          zIndex: 3,
          borderRadius: 50,
          boxShadow: "2px 6px 10px #0F213467",
          opacity:
            process.env.REACT_APP_ENABLE_MEDIMAIL == "true" &&
            user?.email_medimail &&
            selectedResponses.length > 0
              ? 1
              : 0,
          transform:
            process.env.REACT_APP_ENABLE_MEDIMAIL == "true" &&
            user?.email_medimail &&
            selectedResponses.length > 0
              ? "translateY(0)"
              : "translateY(100%)",
          transition: "opacity 0.5s, transform 0.5s",
        }}
      >
        <>
          <div
            style={{
              fontSize: "16px",
              fontFamily: "Poppins",
              fontWeight: "bold",
              borderRadius: "30px",
              width: "25px",
              height: "25px",
              backgroundColor: "#ffa500",
              position: "absolute",
              right: -10,
              top: -3,
              color: "#0b243c",
              zIndex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>{selectedResponses.length}</span>
          </div>
          <Tooltip title="Envoyer les réponses">
            <IconButton
              style={{ margin: "0", padding: 10 }}
              onClick={() => {
                setSendResponsesOpen(true);
              }}
            >
              <AttachEmailOutlined
                fontSize="large"
                style={{ color: "#ffa500" }}
              />
            </IconButton>
          </Tooltip>
        </>
      </div>
      {isExport && (
        <FormPopup>
          <Grid
            item
            display="grid"
            paddingTop="40px"
            paddingLeft="50px"
            paddingRight="50px"
            paddingBottom="40px"
          >
            <FormPopupHeader
              firstTitle={"Exporter"}
              secondTitle={"les réponses"}
              onClose={() => {
                setIsExport(false);
              }}
            />
          </Grid>
          <Grid item display="grid">
            <Grid
              container
              display="flex"
              width="70%"
              justifySelf="center"
              direction="column"
              wrap="nowrap"
              paddingBottom="50px"
            >
              <Grid item display="grid">
                <FullField isMandatory={false} title="Format du fichier">
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    spacing="10px"
                  >
                    <Grid item display="grid">
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        spacing="15px"
                        paddingTop="10px"
                      >
                        <Grid item display="grid">
                          <input
                            type="radio"
                            className="checkbox_type"
                            onChange={() => {
                              return null;
                            }}
                            onClick={() => {
                              setType("csv");
                            }}
                            checked={type === "csv"}
                          />
                        </Grid>
                        <Grid item display="grid">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#0B243C",
                              fontFamily: "Poppins",
                            }}
                          >
                            {" "}
                            CSV
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item display="grid">
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        spacing="15px"
                      >
                        <Grid item display="grid">
                          <input
                            type="radio"
                            className="checkbox_type"
                            onChange={() => {
                              return null;
                            }}
                            onClick={() => {
                              setType("pdf");
                            }}
                            checked={type === "pdf"}
                          />
                        </Grid>
                        <Grid item display="grid">
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#0B243C",
                              fontFamily: "Poppins",
                            }}
                          >
                            {" "}
                            PDF
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      paddingTop="30px"
                    >
                      {!isPDFExportAllLoading && (
                        <Grid item display="grid">
                          <button
                            className="button_modals_class grey_button"
                            style={{
                              border: "none",
                              boxShadow: "none",
                              margin: "0 0 0 0",
                            }}
                            onClick={() => {
                              setType("csv");
                              setIsExport(false);
                            }}
                          >
                            Annuler
                          </button>
                        </Grid>
                      )}
                      {isPDFExportAllLoading && (
                        <Grid item display="grid">
                          <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            justifyContent="center"
                            alignItems="center"
                            gap="10px"
                          >
                            <Grid item display="grid">
                              <CircularProgress />
                            </Grid>
                            <Grid item display="grid">
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  color: "#0B243C",
                                }}
                              >
                                {`Création du PDF en cours... ${
                                  PDFExportAllStep + 1
                                }/${formatedResponses.length}`}
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item display="grid">
                        {isValidSubmit() && !isPDFExportAllLoading && (
                          <div style={{ marginLeft: "25px" }}>
                            <OrangeButton
                              animation={true}
                              enable={true}
                              type="submit"
                              text={`Exporter le ${type.toUpperCase()}`}
                              onClick={async () => {
                                if (type === "csv") {
                                  exportResponsesCsv(
                                    formatedResponses,
                                    patientList,
                                    props?.questionnaireData
                                  );
                                }
                                if (type === "pdf") {
                                  setIsPDFExportAllLoading(true);
                                  await exportPdf(
                                    (number) => {
                                      setPDFExportAllStep(number);
                                    },
                                    formatedResponses,
                                    patientList,
                                    props?.questionnaireData
                                  );
                                  setPDFExportAllStep(0);
                                  setIsPDFExportAllLoading(false);
                                }
                              }}
                            />
                          </div>
                        )}
                        {!isPDFExportAllLoading && !isValidSubmit() && (
                          <div style={{ marginLeft: "25px" }}>
                            <OrangeButton
                              animation={true}
                              enable={false}
                              type="submit"
                              text="Exporter"
                            />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </FullField>
              </Grid>
            </Grid>
          </Grid>
        </FormPopup>
      )}
      <Grid container direction="column" wrap="nowrap">
        <Grid item display="grid">
          <DisplayTitle {...props} />
        </Grid>
        <Grid item display="grid" paddingTop="15px">
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing="10px"
            style={{
              fontFamily: "Poppins",
              fontSize: "16px",
              color: "#0B243C",
            }}
          >
            <Grid item display="grid">
              <span>Filtrer :</span>
            </Grid>
            <Grid item display="grid">
              <Grid container direction="row" gap="10px">
                <Grid item display="grid">
                  <MySelect
                    options={(() => {
                      const optionsTmp = [];
                      if (!patientList) return optionsTmp;
                      for (const patient of patientList) {
                        if (
                          responses?.find((r) => r?.patientId === patient?.id)
                        ) {
                          optionsTmp.push({
                            value: patient?.id,
                            label: patient?.lastname + " " + patient?.firstname,
                          });
                        }
                      }
                      return optionsTmp;
                    })()}
                    noOptionsMessage={() => "Aucun résultat"}
                    value={!patientSearched?.value ? null : patientSearched}
                    placeholder="Nom du patient"
                    onChange={(e) => {
                      setPatientSearched(e);
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <MySelect
                    options={(() => {
                      const optionsTmp = [];
                      if (!formatedResponses) return optionsTmp;
                      for (const r of formatedResponses) {
                        optionsTmp.push({
                          value: r?.id,
                          label: r?.id,
                        });
                      }
                      return optionsTmp;
                    })()}
                    noOptionsMessage={() => "Aucun résultat"}
                    value={responseSearched ? responseSearched : null}
                    placeholder="ID de la réponse"
                    onChange={(e) => {
                      setResponseSearched(e);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid" marginTop="10px">
          <Grid container direction="row" gap="10px" alignItems={"center"}>
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  color: "#0B243C",
                }}
              >
                Du
              </span>
            </Grid>
            <Grid item display="grid">
              <input
                id="start-date"
                type="date"
                style={{
                  minWidth: "100%",
                  border: "1px solid #D3E0E0",
                  background: "#EDF2F280 0% 0% no-repeat padding-box",
                  borderRadius: "10px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  color: startDate ? "#0B243C" : "#657273",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  height: "42px",
                }}
                value={startDate ? startDate : undefined}
                onChange={(e) => {
                  if (e?.target?.value) {
                    setStartDate(e.target.value);
                  } else {
                    setStartDate(undefined);
                  }
                }}
              />
            </Grid>
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  color: "#0B243C",
                }}
              >
                au
              </span>
            </Grid>
            <Grid item display="grid">
              <input
                id="end-date"
                type="date"
                style={{
                  minWidth: "100%",
                  border: "1px solid #D3E0E0",
                  background: "#EDF2F280 0% 0% no-repeat padding-box",
                  borderRadius: "10px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  color: endDate ? "#0B243C" : "#657273",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  height: "42px",
                }}
                value={endDate ? endDate : undefined}
                onChange={(e) => {
                  if (e?.target?.value) {
                    setEndDate(e.target.value);
                  } else {
                    setEndDate(undefined);
                  }
                }}
              />
            </Grid>
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  color: "#0B243C",
                }}
              >
                inclus
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid" paddingTop="15px">
          <FullField title="Mes conditions" isMandatory={false}>
            <Grid container direction="column" spacing="5px" wrap="nowrap">
              <Grid item display="grid">
                <div style={{ maxWidth: "300px" }}>
                  <MySelect
                    isClearable={false}
                    options={conditionsOptions}
                    noOptionsMessage={() => "Aucun résultat"}
                    value={
                      conditionsType
                        ? conditionsOptions.find(
                            (x) => x.value === conditionsType
                          )
                        : undefined
                    }
                    placeholder=""
                    onChange={(e) => {
                      setConditionsType(e?.value);
                    }}
                  />
                </div>
              </Grid>

              {filterByTextSearched.map((f, index) => {
                return (
                  <Grid item display="grid" key={f.id}>
                    <Grid container direction="row" spacing="5px">
                      <Grid item display="grid">
                        <div style={{ width: "270px" }}>
                          <MySelect
                            placeholder="N'importe quelle question"
                            isClearable={true}
                            options={fieldOptions}
                            onChange={(e) => {
                              const newValue = e?.value;
                              setFilterByTextSearched((state) => {
                                const copyState = [...state];
                                const indexTmp = state.findIndex(
                                  (x) => x.id === f.id
                                );
                                if (indexTmp !== -1) {
                                  copyState[indexTmp].question = newValue;
                                }
                                return copyState;
                              });
                            }}
                            value={
                              !f.question
                                ? null
                                : QuestionnaireUtils.getOptionFromValue(
                                    f.question,
                                    fieldOptions
                                  )
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item display="grid" xs minWidth="200px">
                        <TextInput
                          RightIcon={
                            <SearchOutlined style={{ color: "#0B243C" }} />
                          }
                          height="100%"
                          padding="6px 25px 6px 25px"
                          placeholder="Texte recherché"
                          value={f.textSearched}
                          onChange={(e) => {
                            setFilterByTextSearched((state) => {
                              const copyState = [...state];
                              const indexTmp = state.findIndex(
                                (x) => x.id === f.id
                              );
                              if (indexTmp !== -1) {
                                copyState[indexTmp].textSearched =
                                  e.target.value;
                              }
                              return copyState;
                            });
                          }}
                        />
                      </Grid>
                      {filterByTextSearched.length > 1 && (
                        <Grid item display="grid">
                          <Tooltip title="Supprimer">
                            <IconButton
                              onClick={() => {
                                setFilterByTextSearched((state) =>
                                  state.filter((x) => x.id != f.id)
                                );
                              }}
                              style={{
                                margin: "0",
                                padding: "0",
                              }}
                            >
                              <ClearRounded
                                fontSize="small"
                                style={{ color: "#FF3100" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </FullField>
        </Grid>
        <Grid item display="grid" paddingTop="10px">
          <AddButton
            isActive={false}
            displayText={true}
            text="Ajouter une condition"
            onClick={() => {
              setFilterByTextSearched((state) => [
                ...state,
                { question: undefined, textSearched: undefined, id: nanoid() },
              ]);
            }}
          />
        </Grid>
        <Grid item display="grid" paddingTop="15px">
          <OrangeCheckBox
            value={sortingByRecent}
            onClick={() => setSortingByRecent(!sortingByRecent)}
            text="Trier du plus récent au plus ancien"
          />
        </Grid>
        <Grid item display="grid" paddingTop="5px">
          <OrangeCheckBox
            value={hideEmptyFields}
            onClick={() => setHideEmptyFields(!hideEmptyFields)}
            text="Cacher les réponses vides"
          />
        </Grid>
        <Grid item display="grid" paddingTop="5px">
          <OrangeCheckBox
            value={displayArchived}
            onClick={() => {
              setDisplayArchived(!displayArchived);
              setSelectedResponses([]);
            }}
            text="Afficher uniquement les réponses archivées"
          />
        </Grid>
        <Grid item display="grid" paddingTop="5px">
          <OrangeCheckBox
            value={displayAnonymOnly}
            onClick={() => setDisplayAnonymOnly(!displayAnonymOnly)}
            text="Afficher uniquement les patients anonymes"
          />
        </Grid>
        <Grid item display="grid" paddingTop="5px">
          <OrangeCheckBox
            value={displayRegisteredOnly}
            onClick={() => setDisplayRegisteredOnly(!displayRegisteredOnly)}
            text="Afficher uniquement les patients enregistrés"
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          paddingTop="15px"
        >
          <Grid item display="grid">
            <Grid
              container
              direction="column"
              wrap="nowrap"
              gap="5px"
              justifyContent={"flex-start"}
              alignItems="flex-start"
            >
              <Grid item display="grid">
                <DisplayHowManyResponse filteredResponses={filteredResponses} />
              </Grid>
              {filteredResponses?.length > 0 && (
                <Grid item display="grid">
                  <Grid container direction="column" wrap="nowrap" gap="5px" alignItems={"flex-start"}>
                    <Grid item display="grid">
                      <button
                        onClick={() => {
                          setSelectedResponses((x) => {
                            if (x?.length == 0) {
                              snackbarUse.success(
                                filteredResponses.length == 1
                                  ? `La réponse a bien été séléctionnée.`
                                  : `Les ${filteredResponses.length} réponses ont bien été séléctionnées.`
                              );
                              return [...filteredResponses];
                            }
                            snackbarUse.success(
                              x.length == 1
                                ? `La réponse a bien été déséléctionnée.`
                                : `Les ${x.length} réponses ont bien été déséléctionnées.`
                            );
                            return [];
                          });
                        }}
                        style={{ margin: "0", padding: "0" }}
                        className="clear-text-button"
                      >
                        {selectedResponses?.length > 0
                          ? "Tout déséléctionner"
                          : "Tout séléctionner"}
                      </button>
                    </Grid>
                    {sectionsToHide?.length > 0 && (
                      <Grid item display="grid">
                        <button
                          onClick={() => {
                            setSectionsToHide([]);
                            snackbarUse.success(
                              `Les sections cachées sont désormais visibles.`
                            );
                          }}
                          style={{ margin: "0", padding: "0" }}
                          className="clear-text-button"
                        >
                          {"Réafficher les sections cachées"}
                        </button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item display="grid">
            <button
              onClick={() => {
                setIsExport(true);
                // exportResponsesCsv(formatedResponses, patientList, props?.questionnaireData);
              }}
              style={{ margin: "0", padding: "0" }}
              className="clear-text-button"
            >
              Exporter les réponses filtrées
            </button>
          </Grid>
        </Grid>
        <Grid item display="grid" paddingTop="15px">
          <Grid container direction="column" wrap="nowrap" spacing="10px">
            <>
              {pages?.[actualPage] &&
                pages?.[actualPage]?.map((formatedResponse, index) => {
                  return (
                    <Grid item key={formatedResponse?.id} display="grid">
                      <DisplayFormatedResponse
                        sectionsToHide={sectionsToHide}
                        setSectionsToHide={setSectionsToHide}
                        responseIndex={index}
                        inputFilter={inputFilter}
                        formatedResponse={formatedResponse}
                        checkboxValue={
                          selectedResponses.find(
                            (x) => x?.id === formatedResponse?.id
                          ) !== undefined
                        }
                        onCheckboxClick={() => {
                          setSelectedResponses((state) => {
                            if (
                              state.find((x) => x?.id === formatedResponse?.id)
                            ) {
                              return state.filter(
                                (y) => y?.id !== formatedResponse?.id
                              );
                            }
                            return [...state, formatedResponse];
                          });
                        }}
                        {...props}
                      />
                    </Grid>
                  );
                })}
              {pages?.[actualPage] &&
                formatedResponses &&
                formatedResponses
                  /*
                  .filter(
                    (x) => !pages[actualPage].map((y) => y?.id).includes(x?.id)
                  )
                  */
                  .map((formatedResponse, index) => {
                    return (
                      <Grid
                        item
                        key={formatedResponse?.id}
                        display="grid"
                        id={`full-response-${formatedResponse?.id}`}
                        style={{
                          display: "none",
                        }}
                      >
                        <DisplayFormatedResponse
                          sectionsToHide={sectionsToHide}
                          setSectionsToHide={setSectionsToHide}
                          isOnlyForPDF={true}
                          responseIndex={index}
                          inputFilter={inputFilter}
                          formatedResponse={formatedResponse}
                          checkboxValue={
                            selectedResponses.find(
                              (x) => x?.id === formatedResponse?.id
                            ) !== undefined
                          }
                          onCheckboxClick={() => {
                            setSelectedResponses((state) => {
                              if (
                                state.find(
                                  (x) => x?.id === formatedResponse?.id
                                )
                              ) {
                                return state.filter(
                                  (y) => y?.id !== formatedResponse?.id
                                );
                              }
                              return [...state, formatedResponse];
                            });
                          }}
                          {...props}
                        />
                      </Grid>
                    );
                  })}
            </>
          </Grid>
        </Grid>
        {pages?.length > 0 && (
          <Grid item display="grid" paddingTop="47px">
            <Grid
              container
              direction="row"
              spacing="10px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item display="grid">
                <ConfigResponsePerPage
                  responsesPerPage={responsesPerPage}
                  setResponsesPerPage={setResponsesPerPage}
                  {...props}
                />
              </Grid>
              <Grid item display="grid">
                <ResponseNavigation
                  actualPage={actualPage}
                  setActualPage={setActualPage}
                  pages={pages}
                  {...props}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DisplayResponses;
