import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { openSnackbar } from "../../../redux/snackbarReducer";
import makeAPIRequest from "../../../utils/makeAPIRequest";
import GreyButton from "../../Library/Buttons/GreyButton/GreyButton";
import OrangeButton from "../../Library/Buttons/OrangeButton/OrangeButton";
import ErrorHelp from "../../Library/Errors/ErrorHelp";
import CancellablePopup from "../../Library/Popups/CancellablePopup";
import initQuestionnaire from "../GenerateQuestionnaire/InitObjects/initQuestionnaire";
import { SaveOutlined } from "@mui/icons-material";

const errorMessages = {
  emptyTitle: "Le titre n'est pas renseigné",
  emptySection: "Aucune section n'a été ajoutée",
  noDestinataire: "Aucun destinataire n'a été ajouté",
  noSMSRelanceInterval: "L'intervalle des relances n'est pas renseigné",
  noSMSRelanceDuration: "La durée des relances n'est pas renseignée",
  badDuration: "La durée doit être supérieure ou égale à l'intervalle",
  noSMSRelanceContent: "Le contenu du SMS des relances n'est pas renseigné",
  noQuestionnaireInteractifId: "Aucun questionnaire interactif n'a été sélectionné",
  noQuestionnaireInteractifDayAfter: "Le nombre de jours sans réponse est invalide",
  "": "",
};

const errorSelector = {
  emptyTitle: "#questionnaire-title-input",
  emptySection: "#questionnaire-section-box",
  noDestinataire: "#questionnaire-email-destinataires",
  noSMSRelanceInterval: "#interval-sms-relance-input",
  noSMSRelanceDuration: "#duration-sms-relance-input",
  badDuration: "#duration-sms-relance-input",
  noSMSRelanceContent: "#content-sms-relance-input",
  noQuestionnaireInteractifId: "#select-quest-interactif",
  noQuestionnaireInteractifDayAfter: "#send-interactive-form-days-after-input",
  "": "",
};

const formatQuestionnaireData = (questionnaireData) => {
  const questionnaireDataTmp = { ...questionnaireData };

  for (let i = 0; i < questionnaireDataTmp?.sections?.length; i++) {
    for (
      let j = 0;
      j < questionnaireDataTmp?.sections[i]?.fields?.length;
      j++
    ) {
      for (
        let k = 0;
        k < questionnaireDataTmp?.sections[i]?.fields[j]?.length;
        k++
      ) {
        if (!questionnaireDataTmp?.sections[i]?.fields[j][k]?.option) {
          questionnaireData.sections[i].fields[j][k].option = [];
        }
      }
    }
  }
  return questionnaireDataTmp;
};

const DisplayButtons: React.FC<any> = (props) => {
  const history = useHistory();
  const [isCreateLoading, setIsCreateLoading] = React.useState(false);
  const [isEditFastLoading, setIsEditFastLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [isSuccessCreatePopup, setIsSuccessCreatePopup] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const { questionnaireData, setQuestionnaireData, isCreate, isEdit } = props;

  const isValidSubmit = () => {
    const invalidConditions = {
      emptyTitle: !questionnaireData?.title,
      emptySection:
        !questionnaireData?.sections ||
        questionnaireData?.sections?.length === 0,
      noDestinataire:
        questionnaireData?.sendEmailAfterResponse &&
        (!questionnaireData?.emailDestinataires ||
          questionnaireData?.emailDestinataires?.length === 0),
      noSMSRelanceInterval:
        questionnaireData?.sendSMSRelance &&
        !questionnaireData?.dayIntervalSMSRelance,
      noSMSRelanceDuration:
        questionnaireData?.sendSMSRelance &&
        !questionnaireData?.durationSMSRelance,
      badDuration:
        questionnaireData?.sendSMSRelance &&
        parseInt(questionnaireData?.dayIntervalSMSRelance) >
          parseInt(questionnaireData?.durationSMSRelance),
      noSMSRelanceContent:
        questionnaireData?.sendSMSRelance &&
        !questionnaireData?.contentSMSRelance && !questionnaireData?.contentSMSRelanceReplaceByLastSMS,
      noQuestionnaireInteractifId:
        questionnaireData?.sendQuestionnaireInteractif?.isActive &&
        !questionnaireData?.sendQuestionnaireInteractif?.id,
      noQuestionnaireInteractifDayAfter:
        questionnaireData?.sendQuestionnaireInteractif?.isActive &&
        !questionnaireData?.sendQuestionnaireInteractif?.dayAfter,
    };

    for (const condition of Object.keys(invalidConditions)) {
      if (invalidConditions[condition]) {
        if (!errorMessages?.[condition]) {
          return false;
        }
        if (error !== condition) setError(condition);
        return false;
      }
    }
    if (error !== "") setError("");
    return true;
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      {isSuccessCreatePopup && (
        <CancellablePopup
          firstTitle={isCreate ? "Créer" : "Modifier"}
          secondTitle="un questionnaire"
          message={`Le questionnaire '${questionnaireData?.title}' a bien été ${
            isCreate ? "crée" : "modifié"
          }.`}
          cancelButtonText="Retour"
          onCancel={() => {
            history.push("/home?f=questionnaires");
          }}
          confirmButtonText="Créer un nouveau questionnaire"
          onConfirm={() => {
            setIsSuccessCreatePopup(false);
            setQuestionnaireData(initQuestionnaire());
            history.push(`/create_questionnaire`);
          }}
        />
      )}
      {error?.length > 0 && (
        <Grid item display="grid" paddingTop="8px">
          <ErrorHelp
            errorMessages={errorMessages}
            errorSelector={errorSelector}
            error={error}
          />
        </Grid>
      )}
      {isCreateLoading && (
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!isCreateLoading && (
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            display="flex"
            columnSpacing="15px"
            rowSpacing="10px"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item display="grid">
              <GreyButton
                animation={false}
                enable={true}
                text="Réinitialiser"
                onClick={() => {
                  return setQuestionnaireData(initQuestionnaire());
                }}
              />
            </Grid>
            <Grid item display="grid">
              {isValidSubmit() ? (
                <>
                  {!isCreate && !isEditFastLoading && (
                    <div
                      style={{
                        position: "fixed",
                        backgroundColor: "#0b243c",
                        bottom: 50,
                        right: 50,
                        zIndex: 3,
                        borderRadius: 50,
                        boxShadow: "2px 6px 10px #0F213467",
                      }}
                    >
                      <>
                        <Tooltip title="Sauvegarder">
                          <IconButton
                            disabled={isEditFastLoading ? true : false}
                            style={{
                              margin: "0",
                              padding: 10,
                              cursor: isEditFastLoading
                                ? "not-allowed"
                                : "pointer",
                            }}
                            onClick={async () => {
                              // Update form here
                              setIsEditFastLoading(true);
                              makeAPIRequest(
                                "post",
                                "/questions/form/update",
                                formatQuestionnaireData(questionnaireData),
                                "v3"
                              )
                                .then((res) => {
                                  dispatch(
                                    openSnackbar({
                                      message: `Le questionnaire a bien été modifié.`,
                                      type: "success",
                                      duration: 3000,
                                    })
                                  );
                                  setIsEditFastLoading(false);
                                })
                                .catch((err) => {
                                  setIsEditFastLoading(false);
                                  dispatch(
                                    openSnackbar({
                                      message: `Le modification du questionnaire '${questionnaireData?.title}' a échouée.`,
                                      type: "error",
                                      duration: 3000,
                                    })
                                  );
                                });
                            }}
                          >
                            <SaveOutlined
                              fontSize="large"
                              style={{ color: "#ffa500" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    </div>
                  )}
                  <OrangeButton
                    animation={false}
                    enable={true}
                    type="submit"
                    text={isCreate ? "Créer" : "Modifier"}
                    onClick={
                      isCreate
                        ? () => {
                            setIsCreateLoading(true);
                            makeAPIRequest(
                              "post",
                              "/questions/form/create",
                              formatQuestionnaireData(questionnaireData),
                              "v3"
                            )
                              .then((res) => {
                                setIsSuccessCreatePopup(true);
                                setIsCreateLoading(false);
                              })
                              .catch((err) => {
                                setIsCreateLoading(false);
                                dispatch(
                                  openSnackbar({
                                    message: err?.response?.data?.error
                                      ? err?.response?.data?.error
                                      : `La création du questionnaire '${questionnaireData?.title}' a échouée`,
                                    type: "error",
                                    duration: 5000,
                                  })
                                );
                              });
                          }
                        : () => {
                            // Update form here
                            setIsCreateLoading(true);
                            makeAPIRequest(
                              "post",
                              "/questions/form/update",
                              formatQuestionnaireData(questionnaireData),
                              "v3"
                            )
                              .then((res) => {
                                setIsSuccessCreatePopup(true);
                                setIsCreateLoading(false);
                              })
                              .catch((err) => {
                                setIsCreateLoading(false);
                                dispatch(
                                  openSnackbar({
                                    message: `Le modification du questionnaire '${questionnaireData?.title}' a échouée`,
                                    type: "error",
                                    duration: 3000,
                                  })
                                );
                              });
                          }
                    }
                  />
                </>
              ) : (
                <OrangeButton
                  animation={false}
                  enable={false}
                  type="submit"
                  text={isCreate ? "Créer" : "Modifier"}
                  onClick={() => {
                    return null;
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DisplayButtons;
