import { Grid } from "@mui/material";
import { AddAlertOutlined, AnalyticsRounded, GroupOutlined, HistoryOutlined, PaidOutlined, PermIdentityOutlined, PersonAddOutlined, QueryStatsRounded, ReceiptRounded, ReportOutlined, RuleOutlined, SettingsOutlined } from '@mui/icons-material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import BoxTitle from '../../components/Library/Box/BoxTitle';

const AdministrationItemBox: React.FC<any> = (props) => {
  const {
    actualPage,
    navigate,
    text,
    Icon,
  } = props;

  return (
    <Link
      to={`/${navigate}`}
      style={{ textDecoration: "none" }}>
      <span
        style={actualPage === navigate ?
          {
            borderTop: '1px solid #E6EBF0',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 500,
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '18px',
            paddingRight: '20px',
            letterSpacing: '0px',
            color: '#0B243C',
            textAlign: 'left',
          }
          :
          {
            borderTop: '1px solid #E6EBF0',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 500,
            height: '50px',
            paddingLeft: '18px',
            paddingRight: '20px',
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '0px',
            color: '#5BB9C1',
            textAlign: 'left',
          }
        }>
        <span style={{ paddingRight: '16px' }}>{Icon}</span>
        {text}
      </span>
    </Link>
  );
};

const AdministrationBox: React.FC<any> = (props) => {
  const {
    page
  } = props;

  return (
    <Grid
      container
      direction='column'
      wrap="nowrap"
      display='flex'
      paddingLeft='41px'
      paddingTop='26px'
      paddingRight='41px'
      paddingBottom='60px'
      minWidth='520px'
      style={{
        background: 'rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box',
        borderRadius: '24px',
      }}>
      <Grid item display='flex'>
        <BoxTitle
          first='Menu'
          second='Admin' />
      </Grid>
      <Grid item display='flex' paddingLeft='15%' paddingRight='15%' paddingTop='43px'>
        <Grid container direction='column' wrap="nowrap" justifyContent='center' display='flex'>
          <Grid item display='grid'>
            <AdministrationItemBox actualPage={page} text="Création de compte" navigate='admin_create_account' Icon={<PersonAddOutlined />} />
          </Grid>
          <Grid item display='grid'>
            <AdministrationItemBox actualPage={page} text="Gestion des incidents" navigate='admin_gestion_incidents' Icon={<ReportOutlined />} />
          </Grid>
          <Grid item display='grid'>
            <AdministrationItemBox actualPage={page} text="Consommation de crédits" navigate='admin_consommation' Icon={<PaidOutlined />} />
          </Grid>
          <Grid item display='grid'>
            <AdministrationItemBox actualPage={page} text="Historique des activités" navigate='admin_activity_history' Icon={<HistoryOutlined />} />
          </Grid>
          <Grid item display='grid'>
            <AdministrationItemBox actualPage={page} text='Gestion des paramètres' navigate='admin_parameters' Icon={<SettingsOutlined />} />
          </Grid>
          <Grid item display='grid'>
            <AdministrationItemBox actualPage={page} text='Gestion des utilisateurs' navigate='admin_users' Icon={<PermIdentityOutlined />} />
          </Grid>
          {/*
            <Grid item display='grid'>
              <AdministrationItemBox actualPage={page} text='Gestion des conseils' navigate='admin' Icon={<RuleOutlined />} />
            </Grid>
          */}
          <Grid item display='grid'>
            <AdministrationItemBox actualPage={page} text='Gestion des groupes' navigate='admin_groups' Icon={<GroupOutlined />} />
          </Grid>
          <Grid item display='grid' borderBottom='1px solid #E6EBF0'>
            <AdministrationItemBox actualPage={page} text='Gestion des alertes' navigate='admin_alerts' Icon={<AddAlertOutlined />} />
          </Grid>
          <Grid item display='grid' borderBottom='1px solid #E6EBF0'>
            <AdministrationItemBox actualPage={page} text='Gestion des factures' navigate='admin_factures' Icon={<ReceiptRounded />} />
          </Grid>
          <Grid item display='grid' borderBottom='1px solid #E6EBF0'>
            <AdministrationItemBox actualPage={page} text='Statistiques conseils' navigate='admin_others_statistics' Icon={<QueryStatsRounded />} />
          </Grid>
          <Grid item display='grid' borderBottom='1px solid #E6EBF0'>
            <AdministrationItemBox actualPage={page} text='Chiffres clés' navigate='chiffres_cles' Icon={<AnalyticsRounded />} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdministrationBox;