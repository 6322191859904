import React, { useState, useEffect } from "react";
import "../profile.css";
import "../send.css";
import "@pathofdev/react-tag-input/build/index.css";
import TutorialService from "../../services/TutorialService";
import "../../styles/SendMessages.scss";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import "./MessagesContent.css";
import {
  ClearRounded,
  EditRounded,
  SettingsRounded,
} from "@mui/icons-material";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/snackbarReducer";
import MySelect, { NewGenericSelect } from "../Library/Select/MySelect";
import FullField from "../Fields/FullField";
import MessageNavigation from "./MessageNavigation";
import formatDate, { formatDateEN } from "./format/formatDate";
import formatDestinataireName from "./format/formatDestinataireName";
import formatDestinataireTarget from "./format/formatDestinataireTarget";
import FormPopup from "../FormPopup/FormPopup";
import FormPopupHeader from "../FormPopup/FormPopupHeader";
import AdviceTitleField from "../Fields/AdviceFields/AdviceTitleField";
import ErrorHelp from "../Library/Errors/ErrorHelp";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";
import makeAPIRequest from "../../utils/makeAPIRequest";
import CancellablePopup from "../Library/Popups/CancellablePopup";
import convertSmsModeStatus, {
  convertOrangeDiffusionState,
} from "../../utils/format/convertSmsModeStatus";
import { getPatientNameById } from "../Questionnaire/Response/DisplayResponses";
import useQuery from "../../utils/hooks/useQuery";
import { NameWithSorting } from "../PatientList/MesPatientTableTitles";
import { useHistory } from "react-router";
import {
  GenericTextInput,
  useOpenSnackbar,
} from "../../pages/CreateOrEditChatbotLexPage";
import IPatientData from "../../types/Patient";
import formatPhone from "../../utils/format/formatPhone";
import { GenericSelect } from "../../pages/QuestionnairePage";
import TextInput from "../Library/Inputs/TextInput";
import { MoreOrLessButtons } from "../../pages/ChatbotLexPage";

const errorMessages = {
  emptyDate: "La date de déclenchement n'est pas renseignée.",
  emptyTime: "L'heure de déclenchement n'est pas renseignée.",
  "": "",
};

const errorSelector = {
  emptyDate: "#schedule-message-date",
  emptyTime: "#schedule-message-time",
  "": "",
};

interface IMessagesContentProps {
  isSheduled: boolean;
}

const TableTitles: React.FC<{
  isScheduled: any;
}> = (props) => {
  return (
    <>
      <span className="sheduled-table-grid-title">Date</span>
      <span className="sheduled-table-grid-title">Destinataire</span>
      <span className="sheduled-table-grid-title">Message</span>
      {!props.isScheduled && (
        <span className="sheduled-table-grid-title">Statut</span>
      )}
    </>
  );
};

const GenericSelectPatient: React.FC<{
  placeholder?: string;
  onPatientClick: (patient: IPatientData) => void;
}> = (props) => {
  const [value, setValue] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [listPatients, setListPatients] = React.useState<IPatientData[]>([]);
  const [isFakeLoading, setIsFakeLoading] = React.useState<boolean>(false); // Chargement fictif
  const snackbarOpenUse = useOpenSnackbar();

  const fakeLoadingTimeout = React.useRef<NodeJS.Timeout | null>(null);
  const requestTimeout = React.useRef<NodeJS.Timeout | null>(null);

  const fetchPatients = (searchValue: string) => {
    setIsLoading(true);
    setIsFakeLoading(false); // Désactiver le chargement fictif
    makeAPIRequest("post", "/patients/search", { value: searchValue }, "v3")
      .then((res) => {
        const patients: IPatientData[] = res?.data;
        setListPatients(patients?.length > 0 ? patients : []);
      })
      .catch((err) => {
        snackbarOpenUse.error(
          "Impossible de récupérer la liste des patients.",
          err
        );
        setListPatients([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    // Réinitialiser les délais existants
    if (fakeLoadingTimeout.current) {
      clearTimeout(fakeLoadingTimeout.current);
    }
    if (requestTimeout.current) {
      clearTimeout(requestTimeout.current);
    }

    if (value?.length >= 1) {
      // Déclencher le chargement fictif après 1 seconde d'inactivité
      fakeLoadingTimeout.current = setTimeout(() => {
        setIsFakeLoading(true);
      }, 0);

      // Exécuter la requête après 3 secondes d'inactivité
      requestTimeout.current = setTimeout(() => {
        fetchPatients(value);
      }, 3000);
    } else {
      setListPatients([]); // Réinitialiser la liste si la valeur est vide
      setIsFakeLoading(false); // Désactiver le chargement fictif
    }

    return () => {
      if (fakeLoadingTimeout.current) {
        clearTimeout(fakeLoadingTimeout.current);
      }
      if (requestTimeout.current) {
        clearTimeout(requestTimeout.current);
      }
    };
  }, [value]);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item display="grid">
        <GenericTextInput
          fieldTitleInPlaceholderOnly={true}
          fieldTitle={props.placeholder}
          isMandatory={false}
          id="input-select-patient"
          value={value}
          onChange={(e) => {
            setValue(e?.target?.value);
          }}
        />
        {value && (
          <Grid item display="grid" paddingLeft="0px">
            <Dropdown.Menu
              show
              style={{
                borderRadius: "0px 10px 10px 10px",
                zIndex: 100,
                padding: "0",
                marginTop: "0px",
                marginLeft: "6px",
                overflow: "none",
                width: "230px",
              }}
            >
              <ul
                className="drowDownSearch"
                style={{
                  zIndex: 100,
                  width: "100%",
                  maxHeight: "300px",
                  overflow: "auto",
                  minHeight: "50px",
                }}
              >
                {isFakeLoading || isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "15px",
                    }}
                  >
                    <CircularProgress
                      style={{
                        color: "#0B243C",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </div>
                ) : (
                  <>
                    {listPatients?.length == 0 ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "15px",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Poppins",
                              color: "#0B243C",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            Aucun patient trouvé
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {listPatients.map((patient, index) => {
                          return (
                            <li
                              key={patient?.id}
                              className="PatientUnit"
                              style={{
                                height: "100%",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                flexDirection: "column",
                                display: "flex",
                              }}
                              onClick={() => {
                                props.onPatientClick(patient);
                                setValue("");
                                setIsLoading(false);
                                setIsFakeLoading(false);
                                setListPatients([]);
                              }}
                            >
                              <Grid
                                container
                                direction="row"
                                columnSpacing="4px"
                              >
                                {!patient?.firstname && !patient?.lastname && (
                                  <Grid item display="grid">
                                    <span
                                      style={{
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                        letterSpacing: "0px",
                                        color: "#0B243C",
                                        textAlign: "left",
                                      }}
                                    >
                                      {"Aucun nom renseigné"}
                                    </span>
                                  </Grid>
                                )}
                                <Grid item display="grid">
                                  <span
                                    style={{
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      letterSpacing: "0px",
                                      color: "#0B243C",
                                      textAlign: "left",
                                    }}
                                  >
                                    {patient?.lastname ? patient.lastname : ""}
                                  </span>
                                </Grid>
                                <Grid item display="grid">
                                  <span
                                    style={{
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      letterSpacing: "0px",
                                      color: "#0B243C",
                                      textAlign: "left",
                                    }}
                                  >
                                    {patient?.firstname
                                      ? patient.firstname
                                      : ""}
                                  </span>
                                </Grid>
                              </Grid>
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  letterSpacing: "0px",
                                  color: "#0B243C",
                                  textAlign: "left",
                                }}
                              >
                                {patient.phone
                                  ? formatPhone(patient.phone)
                                  : "À compléter"}
                              </span>
                            </li>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </ul>
            </Dropdown.Menu>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

GenericSelectPatient.defaultProps = {
  placeholder: "Nom ou n° tel patient",
};

const MessagesContent = (props: IMessagesContentProps) => {
  const { isSheduled } = props;
  const [maxItem, setMaxItem] = React.useState<number>(10);
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState<any>([]);
  const [actualDestinataire, setActualDestinataire] =
    useState<IPatientData>(undefined);
  const [actualFunctionality, setActualFunctionality] = useState({
    label: "Conseils",
    value: "conseils",
  });
  const snackbarOpenUse = useOpenSnackbar();
  const patientIdQuery = useQuery().get("p");

  React.useEffect(() => {
    if (!patientIdQuery) {
      return;
    }
    makeAPIRequest("post", "/patient/by_id", { id: patientIdQuery }, "v3")
      .then((res) => {
        const patient: IPatientData = res?.data;
        if (patient) {
          setActualDestinataire(patient);
        } else {
          setActualDestinataire(undefined);
        }
      })
      .catch((err) => {
        snackbarOpenUse.error(
          `Impossible de trouver le patient dont l'ID est ${patientIdQuery}.`
        );
        setActualDestinataire(undefined);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [patientIdQuery]);

  const transformMessagesAlgo = (messages) => {
    const newMessages = [];

    for (let i = 0; i < messages?.length; i++) {
      let isFound = false;
      for (let j = 0; j < newMessages?.length; j++) {
        const index = newMessages[j].findIndex(
          (m) =>
            m?.patient_id === messages[i]?.patient_id &&
            m?.conseil_id === messages[i]?.conseil_id &&
            m?.pack_id &&
            messages[i]?.pack_id &&
            m?.pack_id === messages[i]?.pack_id
        );
        if (index !== -1) {
          isFound = true;
          if (messages[i]?.free_text != "") {
            // Pour ajouter les free text
            newMessages.push([messages[i]]);
            break;
          }
          if (
            !newMessages?.[j]?.find(
              (element) => element?.message_id === messages[i]?.message_id
            ) // Pour ajouter les conseils de pack dans le tableau
          ) {
            newMessages?.[j]?.push(messages[i]);
            newMessages?.[j]?.sort((elementA, elementB) => {
              const first = elementA?.date?.toUpperCase();
              const second = elementB?.date?.toUpperCase();
              return first < second ? -1 : first > second ? 1 : 0;
            });
          }
        }
      }
      if (!isFound) {
        newMessages.push([messages[i]]);
        continue;
      }
    }
    return newMessages;
  };

  const fetchMessages = (afterCallback = null) => {
    setIsLoading(true);
    makeAPIRequest(
      "post",
      "/conseils/sended_conseils_with_filters",
      {
        functionality: actualFunctionality?.value
          ? actualFunctionality.value
          : "conseils",
        statut: isSheduled ? "not_sended" : "sended",
        page: 0,
        patientId: actualDestinataire?.id ? actualDestinataire.id : undefined,
      } as {
        functionality: "conseils" | "forms";
        statut: "all" | "sended" | "not_sended";
        page: number;
        patientId?: string;
      },
      "v3"
    )
      .then((res) => {
        const result = res.data.data.sended_conseils;
        const resultWithAlgo = transformMessagesAlgo(result);
        setMessages(resultWithAlgo);
        if (afterCallback) {
          afterCallback();
        }
      })
      .catch((err) => {
        snackbarOpenUse.error(
          "Impossible de récupérer la liste des messages.",
          err
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    fetchMessages();
  }, [isSheduled, actualDestinataire, actualFunctionality]);

  const deleteScheduledMessage = (id) => {
    makeAPIRequest(
      "post",
      "/conseils/cancel_scheduled",
      {
        ids: [id],
      },
      "v2"
    ).then((res) => {
      console.log(res);
    });
  };

  const TableContents: React.FC<any> = (props) => {
    const history = useHistory();
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [newDate, setNewDate] = useState<string>(null);
    const [newHour, setNewHour] = useState(null);
    const [popupEditLoading, setPopupEditLoading] = React.useState(false);
    const [deletePackLoading, setDeletePackLoading] = React.useState(null);
    const [packToDelete, setPackToDelete] = useState(null);
    const [scheduleId, setScheduleId] = useState(null);
    const [packScheduleId, setPackScheduleId] = useState(null);
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const {
      date,
      destinataireName,
      destinataireTarget,
      allMessages,
      freeText,
      messages,
      smsmode_status,
      orange_diffusion_state,
    } = props;

    const formatPackDate = (dateToFormat) => {
      const newDate = formatDate(dateToFormat);

      return (newDate as any)?.date + " à " + (newDate as any)?.hours;
    };

    const isValidSubmit = () => {
      const invalidConditions = {
        emptyDate: !newDate,
        emptyTime: !newHour,
      };

      for (const condition of Object.keys(invalidConditions)) {
        if (invalidConditions[condition]) {
          if (!errorMessages?.[condition]) {
            return false;
          }
          if (error !== condition) setError(condition);
          return false;
        }
      }
      if (error !== "") setError("");
      return true;
    };

    return (
      <>
        {packToDelete && (
          <CancellablePopup
            firstTitle="Messages"
            secondTitle="programmés"
            messages={[
              "Voulez-vous vraiment supprimer ce pack ?",
              `La programmation des messages sera annulée.`,
            ]}
            confirmButtonText="Supprimer"
            cancelButtonText="Annuler"
            onCancel={() => setPackToDelete(null)}
            onConfirm={() => {
              setPackToDelete(null);
              setDeletePackLoading(allMessages?.[0]?.pack_id);
              const ids = [];
              for (const m of allMessages) {
                ids.push(m?.id);
              }
              makeAPIRequest(
                "post",
                "/conseils/cancel_scheduled",
                {
                  ids: ids,
                },
                "v2"
              )
                .then((res) => {
                  fetchMessages(() => {
                    setDeletePackLoading(null);
                    dispatch(
                      openSnackbar({
                        message: "Le pack a bien été supprimé",
                        type: "success",
                        duration: 3000,
                      })
                    );
                  });
                })
                .catch((err) => {
                  setDeletePackLoading(null);
                  dispatch(
                    openSnackbar({
                      message: "Le pack n'a pas pu être supprimé",
                      type: "error",
                      duration: 3000,
                    })
                  );
                });
            }}
          />
        )}
        {isEditOpen && (
          <FormPopup>
            <Grid
              item
              display="grid"
              paddingTop="40px"
              paddingLeft="50px"
              paddingRight="50px"
              paddingBottom="40px"
            >
              <FormPopupHeader
                firstTitle={"Modifier"}
                secondTitle={"une programmation"}
                onClose={() => {
                  setIsEditOpen(false);
                  setNewDate(null);
                  setNewHour(null);
                  setScheduleId(null);
                  setPackScheduleId(null);
                }}
              />
            </Grid>
            <Grid item display="grid">
              <Grid
                container
                display="flex"
                width="70%"
                justifySelf="center"
                direction="column"
                wrap="nowrap"
                paddingBottom="70px"
              >
                <Grid item display="grid">
                  <FullField isMandatory={false} title="Date de déclenchement">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      wrap="nowrap"
                      spacing="10px"
                    >
                      <Grid item display="grid">
                        <span>Le</span>
                      </Grid>
                      <Grid item display="grid">
                        <input
                          id="schedule-message-date"
                          type="date"
                          style={{
                            minWidth: "100%",
                            border: "1px solid #D3E0E0",
                            background: "#EDF2F280 0% 0% no-repeat padding-box",
                            borderRadius: "10px",
                            paddingRight: "20px",
                            paddingLeft: "20px",
                            color:
                              newDate && newDate?.length > 0
                                ? "#0B243C"
                                : "#657273",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            height: "42px",
                          }}
                          value={newDate}
                          onChange={(e) => setNewDate(e.target.value)}
                        />
                      </Grid>
                      <Grid item display="grid">
                        <span>à</span>
                      </Grid>
                      <Grid item display="grid">
                        <input
                          id="schedule-message-time"
                          type="time"
                          style={{
                            minWidth: "100%",
                            border: "1px solid #D3E0E0",
                            background: "#EDF2F280 0% 0% no-repeat padding-box",
                            borderRadius: "10px",
                            paddingRight: "20px",
                            paddingLeft: "20px",
                            color:
                              newHour && newHour?.length > 0
                                ? "#0B243C"
                                : "#657273",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            height: "42px",
                          }}
                          value={newHour}
                          onChange={(e) => setNewHour(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </FullField>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    paddingTop="30px"
                    wrap="nowrap"
                  >
                    {error?.length > 0 && (
                      <Grid item display="grid">
                        <ErrorHelp
                          errorMessages={errorMessages}
                          errorSelector={errorSelector}
                          error={error}
                        />
                      </Grid>
                    )}
                    <Grid item display="grid" justifyContent="center">
                      {isValidSubmit() ? (
                        <OrangeButton
                          animation={false}
                          enable={true}
                          type="submit"
                          isLoading={popupEditLoading}
                          text={"Mettre à jour"}
                          onClick={() => {
                            setPopupEditLoading(true);
                            const id = scheduleId ? scheduleId : packScheduleId;
                            const toSend = {
                              date: Date.parse(newDate + "T" + newHour + ":00"),
                              update_pack: scheduleId ? false : true,
                            };
                            makeAPIRequest(
                              "post",
                              `/conseils/${id}/update_scheduled`,
                              toSend,
                              "v2"
                            )
                              .then((res) => {
                                if (scheduleId) {
                                  const messagesTmp = [...messages];
                                  for (let i = 0; i < messagesTmp.length; i++) {
                                    const indexTmp = messagesTmp[i].findIndex(
                                      (m) => m.id === scheduleId
                                    );
                                    if (indexTmp !== -1) {
                                      const messageTmp = {
                                        ...messagesTmp[i][indexTmp],
                                      };
                                      messageTmp.date =
                                        newDate.replaceAll("/", "-") +
                                        " " +
                                        newHour.replaceAll("h", ":") +
                                        ":00";
                                      messagesTmp[i][indexTmp] = messageTmp;
                                    }
                                  }
                                  setMessages(messagesTmp);
                                  setIsEditOpen(false);
                                  setPopupEditLoading(false);
                                  dispatch(
                                    openSnackbar({
                                      message:
                                        "Votre programmation a bien été modifiée",
                                      type: "success",
                                      duration: 3000,
                                    })
                                  );
                                } else {
                                  fetchMessages(() => {
                                    setIsEditOpen(false);
                                    setPopupEditLoading(false);
                                    dispatch(
                                      openSnackbar({
                                        message:
                                          "Votre programmation a bien été modifiée",
                                        type: "success",
                                        duration: 3000,
                                      })
                                    );
                                  });
                                }
                              })
                              .catch((err) => {
                                setPopupEditLoading(false);
                                dispatch(
                                  openSnackbar({
                                    message:
                                      "La modification de votre programmation a échouée",
                                    type: "error",
                                    duration: 3000,
                                  })
                                );
                              });
                          }}
                        />
                      ) : (
                        <OrangeButton
                          animation={false}
                          enable={false}
                          type="submit"
                          text={"Mettre à jour"}
                          onClick={() => {
                            return null;
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormPopup>
        )}
        <span className="sheduled-table-grid-content">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              {(() => {
                const dateSplited = date?.date?.split("/");
                if (dateSplited?.length !== 3) return "";
                const yyyy = dateSplited[0];
                const mm = dateSplited[1];
                const dd = dateSplited[2];

                return `${dd}/${mm}/${yyyy}`;
              })()}
            </Grid>
            <Grid item display="grid">
              {(!date?.hours || date?.hours === "00h00") ? "" : date.hours}
            </Grid>
          </Grid>
        </span>
        <span className="sheduled-table-grid-content">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              {destinataireName}
            </Grid>
            <Grid item display="grid">
              {destinataireTarget}
            </Grid>
          </Grid>
        </span>
        <span
          className="sheduled-table-grid-content"
          style={{
            whiteSpace: "pre-line",
          }}
        >
          <Grid container direction="column" wrap="nowrap">
            {allMessages?.length > 1 &&
              isSheduled &&
              allMessages?.[0]?.pack_date && (
                <Grid
                  item
                  marginBottom="20px"
                  marginTop="5px"
                  display="grid"
                  alignSelf="center"
                  border="1px solid #0B243C"
                  borderRadius="15px"
                  paddingRight="10px"
                  paddingLeft="5px"
                >
                  <Grid container direction="row" wrap="nowrap">
                    <Grid item display="grid" padding="5px">
                      <span className="simple-text">Gérer ce pack</span>
                    </Grid>
                    <Grid item display="grid" borderRight="1px solid #0B243C" />
                    <Grid
                      item
                      display="grid"
                      paddingRight="5px"
                      paddingLeft="5px"
                    >
                      <Tooltip title="Modifier">
                        <IconButton
                          onClick={() => {
                            const dateNoFormated = allMessages[0].pack_date;
                            const tmp = dateNoFormated?.split(" ");
                            setIsEditOpen(true);
                            setNewDate(tmp?.[0]);
                            setNewHour(tmp?.[1]?.substring(0, 5));
                            setPackScheduleId(allMessages?.[0]?.pack_id);
                            setScheduleId(null);
                          }}
                          style={{
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          <EditRounded
                            fontSize="small"
                            style={{ color: "#0B243C" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item display="grid" borderRight="1px solid #0B243C" />
                    <Grid item display="grid" paddingLeft="5px">
                      {deletePackLoading == allMessages?.[0]?.pack_id && (
                        <Grid
                          container
                          direction="row"
                          display="flex"
                          paddingLeft="5px"
                          paddingRight="5px"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CircularProgress
                            style={{
                              color: "#0B243C",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </Grid>
                      )}
                      {deletePackLoading != allMessages?.[0]?.pack_id && (
                        <Tooltip title="Supprimer">
                          <IconButton
                            onClick={() => {
                              setPackToDelete(allMessages);
                            }}
                            style={{
                              margin: "0",
                              padding: "0",
                            }}
                          >
                            <ClearRounded
                              fontSize="small"
                              style={{ color: "#FF3100" }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>
          <Grid item display="grid">
            <Grid container direction="column" wrap="nowrap" spacing="10px">
              {allMessages?.map((message, index) => {
                let isPack = false;
                if (allMessages?.length > 1) isPack = true;
                return (
                  <Grid item key={index} display="grid">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      columnSpacing="20px"
                      wrap="nowrap"
                    >
                      {isPack ? (
                        <Grid item display="grid">
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            wrap="nowrap"
                            spacing="20px"
                          >
                            <Grid item display="grid">
                              <span
                                style={{
                                  textAlign: "left",
                                  fontFamily: "Poppins",
                                  fontSize: "13px",
                                  fontWeight: "bold",
                                  color: "#5bb9c1",
                                }}
                              >
                                {index + 1 < 10
                                  ? `0${index + 1}`
                                  : `${index + 1}`}
                              </span>
                            </Grid>
                            <Grid item display="grid">
                              <Grid
                                container
                                direction="column"
                                wrap="nowrap"
                                spacing="3px"
                              >
                                <Grid
                                  item
                                  display="grid"
                                  style={{
                                    whiteSpace: "pre-line",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {message?.free_text
                                    ? message.free_text
                                    : message.content}
                                </Grid>
                                {convertSmsModeStatus(
                                  message?.smsmode_status
                                ) && (
                                  <Grid item display="grid">
                                    <span
                                      style={{
                                        fontFamily: "Poppins",
                                        fontSize: "12px",
                                        color: "#8D9899",
                                        letterSpacing: "0px",
                                      }}
                                    >
                                      {convertSmsModeStatus(
                                        message.smsmode_status
                                      )}
                                    </span>
                                  </Grid>
                                )}
                                <Grid item display="grid">
                                  <span
                                    style={{
                                      fontFamily: "Poppins",
                                      fontSize: "12px",
                                      color: "#8D9899",
                                      letterSpacing: "0px",
                                    }}
                                  >
                                    {formatPackDate(message.date)}
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item display="grid">
                          {freeText ? (
                            <span
                              style={{
                                whiteSpace: "pre-line",
                                wordBreak: "break-word",
                              }}
                            >
                              {`${freeText}\n${
                                message.attachment_path
                                  ? "\n" + message.attachment_path
                                  : ""
                              }`}
                            </span>
                          ) : (
                            <span
                              style={{
                                whiteSpace: "pre-line",
                                wordBreak: "break-word",
                              }}
                            >
                              {message?.content}
                            </span>
                          )}
                        </Grid>
                      )}
                      {isSheduled && (
                        <Grid item display="grid">
                          <Grid
                            container
                            direction="row"
                            spacing="5px"
                            wrap="nowrap"
                          >
                            {[
                              "interactive_forms",
                              "forms",
                              "chatbots",
                            ]?.includes(message?.functionality) && (
                              <Grid item display="grid">
                                <Tooltip title="Gérer">
                                  <IconButton
                                    onClick={() => {
                                      if (
                                        message?.functionality === "chatbots"
                                      ) {
                                        history.push(
                                          message?.patient_id
                                            ? `/home?f=chatbots&tab=mesenvois&p=${message.patient_id}`
                                            : `/home?f=chatbots&tab=mesenvois`
                                        );
                                        return;
                                      }
                                      if (message?.functionality === "forms") {
                                        history.push(
                                          message?.patient_id
                                            ? `/home?f=questionnaires&tab=mesenvois&p=${message.patient_id}`
                                            : `/home?f=questionnaires&tab=mesenvois`
                                        );
                                        return;
                                      }
                                      if (
                                        message?.functionality ===
                                        "interactive_forms"
                                      ) {
                                        if (message?.chatbotId) {
                                          history.push(
                                            message?.patient_id
                                              ? `/chatbot/${message.chatbotId}/reponses?p=${message.patient_id}`
                                              : `/chatbot/${message.chatbotId}/reponses`
                                          );
                                        }
                                        return;
                                      }
                                    }}
                                    style={{
                                      margin: "0",
                                      padding: "0",
                                    }}
                                  >
                                    <SettingsRounded
                                      fontSize="small"
                                      style={{ color: "#0B243C" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                            {message?.functionality === "conseils" && (
                              <Grid item display="grid">
                                <Tooltip title="Modifier">
                                  <IconButton
                                    onClick={() => {
                                      const dateNoFormated = message.date;
                                      const tmp = dateNoFormated?.split(" ");
                                      setIsEditOpen(true);
                                      setNewDate(tmp?.[0]);
                                      setNewHour(tmp?.[1]?.substring(0, 5));
                                      setScheduleId(message.id);
                                    }}
                                    style={{
                                      margin: "0",
                                      padding: "0",
                                    }}
                                  >
                                    <EditRounded
                                      fontSize="small"
                                      style={{ color: "#0B243C" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                            {message?.functionality === "conseils" && (
                              <Grid item display="grid">
                                <Tooltip title="Supprimer">
                                  <IconButton
                                    onClick={() => {
                                      let messagesTmp = [...messages];
                                      for (
                                        let i = 0;
                                        i < messagesTmp.length;
                                        i++
                                      ) {
                                        const indexTmp = messagesTmp[
                                          i
                                        ].findIndex(
                                          (m) => m.id === message?.id
                                        );
                                        if (indexTmp != -1) {
                                          delete messagesTmp[i][indexTmp];
                                          const newArray = messagesTmp[
                                            i
                                          ].filter(
                                            (element) => element != undefined
                                          );
                                          messagesTmp[i] = newArray;
                                          if (messagesTmp[i]?.length === 0) {
                                            delete messagesTmp[i];
                                            const newArray = messagesTmp.filter(
                                              (element) => element != undefined
                                            );
                                            messagesTmp = newArray;
                                          }
                                        }
                                      }
                                      setMessages(messagesTmp);
                                      deleteScheduledMessage(message?.id);
                                      dispatch(
                                        openSnackbar({
                                          message:
                                            "Le message a bien été supprimé",
                                          type: "success",
                                          duration: 3000,
                                        })
                                      );
                                    }}
                                    style={{
                                      margin: "0",
                                      padding: "0",
                                    }}
                                  >
                                    <ClearRounded
                                      fontSize="small"
                                      style={{ color: "#FF3100" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </span>
        {!isSheduled && (
          <span className="sheduled-table-grid-content">
            {orange_diffusion_state
              ? allMessages.length > 1
                ? ""
                : convertOrangeDiffusionState(orange_diffusion_state)
              : allMessages.length > 1
              ? smsmode_status
              : convertSmsModeStatus(smsmode_status)}
          </span>
        )}
      </>
    );
  };

  const calcSmsModeStatusPack = (messagesInPack) => {
    const listSuccessAndError = [];
    if (!messagesInPack) {
      return "";
    }
    for (const m of messagesInPack) {
      const tmpText = convertSmsModeStatus(m?.smsmode_status);
      if (tmpText === "Reçu") {
        listSuccessAndError.push(true);
      }
      if (tmpText && tmpText !== "Reçu") {
        listSuccessAndError.push(false);
      }
    }
    if (
      listSuccessAndError.includes(false) &&
      listSuccessAndError.includes(true)
    ) {
      return "Partiellement reçu";
    }
    if (
      listSuccessAndError.includes(true) &&
      !listSuccessAndError.includes(false)
    ) {
      return "Reçu";
    }
    if (
      listSuccessAndError.includes(false) &&
      !listSuccessAndError.includes(true)
    ) {
      return "Échec";
    }
    return "";
  };

  const getDateFRFromMsg = (message: any) => {
    return message?.[0]?.sended == "1"
      ? formatDate(
          message?.[0]?.pack_date && message?.length > 1
            ? message?.[0]?.pack_date
            : message?.[0]?.sended_date
        )
      : formatDate(
          message?.[0]?.pack_date && message?.length > 1
            ? message?.[0]?.pack_date
            : message?.[0]?.date
        );
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item display="grid">
        <Grid container direction="row" gap="20px" alignItems={"center"}>
          <Grid item display="grid">
            <GenericSelectPatient
              placeholder={
                actualDestinataire
                  ? actualDestinataire?.lastname &&
                    actualDestinataire?.firstname
                    ? actualDestinataire?.lastname +
                      " " +
                      actualDestinataire?.firstname
                    : actualDestinataire?.phone
                  : "Nom ou n° tel patient"
              }
              onPatientClick={(patient) => {
                setActualDestinataire(patient);
              }}
            />
          </Grid>
          <Grid item display="grid">
            <NewGenericSelect
              fixedWidth="200px"
              isClearable={false}
              noOptionsMessage={() => "Aucun résultat"}
              options={[
                {
                  value: "conseils",
                  label: "Conseils",
                },
                {
                  value: "forms",
                  label: "Questionnaires",
                },
                /*
                    {
                      value: "interactive_forms",
                      label: "Questionnaires interactifs",
                    },
                    {
                      value: "chatbots",
                      label: "Chatbots",
                    },
                    */
              ]}
              value={actualFunctionality}
              placeholder="Fonctionnalité"
              onChange={(e) => {
                setActualFunctionality(e);
              }}
            />
          </Grid>
          <Grid item display="grid">
            <button
              onClick={() => {
                setActualDestinataire(undefined);
                setActualFunctionality({
                  label: "Conseils",
                  value: "conseils",
                });
              }}
              className={"delete_advice_button"}
              style={{
                color: "rgb(91, 185, 193)",
                padding: "0",
                margin: "0",
              }}
            >
              Réinit.
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="grid" paddingTop="25px">
        <div
          className={
            !isSheduled ? "sheduled-table-grid-sended" : "sheduled-table-grid"
          }
        >
          <TableTitles isScheduled={isSheduled} />
          {!isLoading &&
            messages?.length > 0 &&
            messages?.slice(0, maxItem)?.map((message, index) => {
              return (
                <TableContents
                  key={message?.[0]?.id}
                  freeText={message?.[0]?.free_text}
                  orange_diffusion_state={message?.[0]?.orange_diffusion_state}
                  smsmode_status={
                    message?.length > 1
                      ? calcSmsModeStatusPack(message)
                      : message?.[0]?.smsmode_status
                  }
                  date={getDateFRFromMsg(message)}
                  destinataireName={formatDestinataireName(
                    message?.[0]?.patient_name
                  )}
                  destinataireTarget={formatDestinataireTarget(
                    message?.[0]?.target
                  )}
                  messages={messages}
                  allMessages={message}
                />
              );
            })}
        </div>

        {messages?.length > 10 && (
          <Grid item display="grid">
            <MoreOrLessButtons
              hideRestant={true}
              items={messages}
              maxItem={maxItem}
              setMaxItem={setMaxItem}
            />
          </Grid>
        )}
      </Grid>

      {messages?.length == 0 && !isLoading && (
        <Grid item display="grid" marginTop="10px">
          <span
            style={{
              fontFamily: "Poppins",
              fontSize: "16px",
              color: "#0B243C",
            }}
          >
            {`Aucun message n'a été trouvé.`}
          </span>
        </Grid>
      )}
      {isLoading && (
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingTop="15px"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default MessagesContent;
