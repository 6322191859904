import FullField from "../../../Fields/FullField";
import * as React from "react";
import CheckBoxField from "../../../Fields/CheckBox/CheckBoxField";
import { Grid } from "@mui/material";
import { GenericDateInput } from "./DisplayDate";
import OrangeCheckBox from "../../../Fields/CheckBox/OrangeCheckBox";
import swal from "sweetalert";
import { useOpenSnackbar } from "../../../../pages/CreateOrEditChatbotLexPage";
import { CloseRounded } from "@mui/icons-material";
import { ButtonIconWithTooltip } from "../../PDF/CreateOrEditPDFQuestionnaire";

export const DisplayPieceJointe: React.FC<any> = (props) => {
  const {
    field,
    sectionIndex,
    fieldIndex,
    fieldRowIndex,
    questionnaireData,
    setQuestionnaireData,
  } = props;
  const title = field?.title ? field.title : "Sans titre";
  const snackbarOpenUse = useOpenSnackbar();
  const fileName = field?.value?.[0] ? field.value[0] : undefined;
  const fileBase64 = field?.value?.[1] ? field.value[1] : undefined;

  return (
    <Grid container direction="column" wrap="nowrap" gap="15px">
      <Grid item display="grid">
        <Grid
          container
          id={"field" + field?.id}
          border="0px solid transparent"
          style={{
            width: "fit-content",
          }}
          borderRadius="10px"
        >
          <FullField
            isQuestionnaire={true}
            title={title}
            isMandatory={field?.isMandatory}
            hideAsterics={field?.hideAsterics ? true : false}
          >
            {fileName && fileBase64 ? (
              <Grid container direction="column" wrap="nowrap" gap="5px">
                {/*fileBase64.includes("image/") && (
                  <Grid item display="grid">
                    <img
                      src={`${fileBase64}`}
                      alt="Pièce jointe"
                      style={{
                        height: "80px",
                        width: "80px",
                        minWidth: "80px",
                        objectFit: "cover",
                      }}
                    />
                  </Grid>
                )*/}
                <Grid item display="grid">
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    gap="5px"
                    alignItems={"center"}
                  >
                    <Grid item display="grid">
                      <a
                        href={fileBase64}
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          color: "#0B243C",
                        }}
                        target="_blank"
                      >
                        {`${fileName}`}
                      </a>
                    </Grid>
                    <Grid item display="grid">
                      <ButtonIconWithTooltip
                        Icon={
                          <CloseRounded
                            style={{ color: "#0B243C", fontSize: "16px" }}
                          />
                        }
                        onClick={() => {
                          setQuestionnaireData((x) => {
                            const before = {...x};
                            if (x?.sections) {
                              for (let i = 0; i < x.sections.length; i++) {
                                if (x?.sections[i]) {
                                  for (let j = 0; j < x?.sections?.[i]?.fields?.length; j++) {
                                    if (x?.sections?.[i]?.fields[j]) {
                                      for (let k = 0; k < x?.sections?.[i]?.fields[j]?.length; k++) {
                                        if (x?.sections?.[i]?.fields[j][k]) {
                                          if (x.sections[i].fields[j][k].id == field?.id) {
                                            x.sections[i].fields[j][k].value = "";
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            return before;
                          });
                        }}
                        tooltipText=""
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <input
                  type="file"
                  name={`file-piece-jointe-questionnaire`}
                  id={`file-piece-jointe-questionnaire`}
                  accept="image/png, image/jpeg, image/jpg, application/pdf, audio/mpeg, audio/mp3, video/mp4, video/webm"
                  onChange={(e) => {
                    const fileReader = new FileReader();
                    if (e.target.files[0] != null) {
                      if (e.target.files[0].size >= 20971520) {
                        snackbarOpenUse.error(
                          "Le fichier est trop lourd, il ne doit pas dépasser 20MB."
                        );
                      } else {
                        fileReader.readAsDataURL(e.target.files[0]);
                        fileReader.onload = (event) => {
                          if (
                            event?.target?.result &&
                            e?.target?.files?.[0]?.name
                          ) {
                            setQuestionnaireData((x) => {
                              const before = {...x};
                              if (x?.sections) {
                                for (let i = 0; i < x.sections.length; i++) {
                                  if (x?.sections[i]) {
                                    for (let j = 0; j < x?.sections?.[i]?.fields?.length; j++) {
                                      if (x?.sections?.[i]?.fields[j]) {
                                        for (let k = 0; k < x?.sections?.[i]?.fields[j]?.length; k++) {
                                          if (x?.sections?.[i]?.fields[j][k]) {
                                            if (x.sections[i].fields[j][k].id == field?.id) {
                                              x.sections[i].fields[j][k].value = [
                                                e?.target?.files?.[0]?.name,
                                                event.target.result,
                                              ];
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              return before;
                            });
                          } else {
                            snackbarOpenUse.error(
                              "Impossible de récupérer les informations concernant votre fichier."
                            );
                          }
                        };
                      }
                    }
                  }}
                  style={{ display: "none", cursor: "pointer", width: "100%" }}
                />
                <label
                  style={{ cursor: "pointer", width: "100%" }}
                  htmlFor={`file-piece-jointe-questionnaire`}
                >
                  <div
                    style={{
                      boxShadow: "1px 3px 5px #0F213467",
                      paddingTop: "7px",
                      paddingBottom: "7px",
                      paddingLeft: "20px",
                      maxWidth: "330px",
                      paddingRight: "20px",
                      borderRadius: "18px",
                      opacity: 1,
                      background: "#0B243C 0% 0% no-repeat padding-box",
                      letterSpacing: "0px",
                      color: "#FFFFFF",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "Poppins",
                      justifySelf: "flex-end",
                    }}
                  >
                    Joindre un fichier
                  </div>
                </label>
              </>
            )}
          </FullField>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const DisplayDateSortie: React.FC<any> = (props) => {
  const {
    field,
    sectionIndex,
    fieldIndex,
    fieldRowIndex,
    questionnaireData,
    setQuestionnaireData,
  } = props;

  const title = field?.title ? field.title : "Sans titre";
  const valueYesOrNo =
    field?.value?.length >= 3 ? field.value.substring(0, 3) : "";
  const iDontKnow = field?.value?.includes("Je ne sais pas");
  const dateValue =
    iDontKnow ||
    valueYesOrNo === "Non" ||
    !field?.value ||
    !field?.value?.includes(" - ")
      ? ""
      : field.value.split(" - ")[1];

  return (
    <Grid container direction="column" wrap="nowrap" gap="15px">
      <Grid item display="grid">
        <Grid
          container
          id={"field" + field?.id}
          border="0px solid transparent"
          style={{
            width: "fit-content",
          }}
          borderRadius="10px"
        >
          <FullField
            isQuestionnaire={true}
            title={title}
            isMandatory={field?.isMandatory}
            hideAsterics={field?.hideAsterics ? true : false}
          >
            <Grid
              container
              direction="column"
              wrap="nowrap"
              display="flex"
              rowGap="10px"
              columnGap="30px"
              paddingTop="5px"
            >
              {["Oui", "Non"]?.map((option, index) => (
                <Grid item key={index} display="grid">
                  <CheckBoxField
                    text={option}
                    value={option === valueYesOrNo}
                    onClick={() => {
                      setQuestionnaireData((x) => {
                        const before = {...x};
                        if (x?.sections) {
                          for (let i = 0; i < x.sections.length; i++) {
                            if (x?.sections[i]) {
                              for (let j = 0; j < x?.sections?.[i]?.fields?.length; j++) {
                                if (x?.sections?.[i]?.fields[j]) {
                                  for (let k = 0; k < x?.sections?.[i]?.fields[j]?.length; k++) {
                                    if (x?.sections?.[i]?.fields[j][k]) {
                                      if (x.sections[i].fields[j][k].id == field?.id) {
                                        x.sections[i].fields[j][k].value = option;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        return before;
                      });
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </FullField>
        </Grid>
      </Grid>
      {valueYesOrNo === "Oui" && (
        <Grid item display="grid">
          <FullField
            isQuestionnaire={true}
            title={"À quelle date ?"}
            isMandatory={field?.isMandatory}
            hideAsterics={field?.hideAsterics ? true : false}
          >
            <Grid container direction="column" wrap="nowrap" gap="10px">
              {!iDontKnow && (
                <Grid item display="grid">
                  <GenericDateInput
                    fieldTitle=""
                    id="date-sortie"
                    onChange={(e) => {
                      const questionnaireDataTmp = { ...questionnaireData };
                      const v = e?.target?.value;

                      if (v) {
                        questionnaireDataTmp.sections[sectionIndex].fields[
                          fieldRowIndex
                        ][fieldIndex].value = `${valueYesOrNo} - ${v}`;
                      } else {
                        questionnaireDataTmp.sections[sectionIndex].fields[
                          fieldRowIndex
                        ][fieldIndex].value = `${valueYesOrNo}`;
                      }
                      setQuestionnaireData(questionnaireDataTmp);
                    }}
                    value={dateValue}
                    isMandatory={false}
                  />
                </Grid>
              )}
              <Grid item display="grid">
                <OrangeCheckBox
                  text="Je ne sais pas"
                  onClick={() => {
                    const questionnaireDataTmp = { ...questionnaireData };

                    if (!iDontKnow) {
                      questionnaireDataTmp.sections[sectionIndex].fields[
                        fieldRowIndex
                      ][fieldIndex].value = `${valueYesOrNo} - Je ne sais pas`;
                    } else {
                      questionnaireDataTmp.sections[sectionIndex].fields[
                        fieldRowIndex
                      ][fieldIndex].value = `${valueYesOrNo}`;
                    }
                    setQuestionnaireData(questionnaireDataTmp);
                  }}
                  value={iDontKnow ? true : false}
                />
              </Grid>
            </Grid>
          </FullField>
        </Grid>
      )}
    </Grid>
  );
};

const DisplayUniqueChoice: React.FC<any> = (props) => {
  const {
    field,
    sectionIndex,
    fieldIndex,
    fieldRowIndex,
    questionnaireData,
    setQuestionnaireData,
  } = props;

  return (
    <Grid
      container
      id={"field" + field?.id}
      border="0px solid transparent"
      style={{
        width: "fit-content",
      }}
      borderRadius="10px"
    >
      <FullField
        isQuestionnaire={true}
        title={field?.title}
        isMandatory={field?.isMandatory}
        hideAsterics={field?.hideAsterics ? true : false}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          display="flex"
          rowGap="10px"
          columnGap="30px"
          paddingTop="5px"
        >
          {field?.option?.map((option, index) => (
            <Grid item key={index} display="grid">
              <CheckBoxField
                text={option}
                value={option === field?.value}
                onClick={() => {
                  setQuestionnaireData((x) => {
                    const before = {...x};
                    if (x?.sections) {
                      for (let i = 0; i < x.sections.length; i++) {
                        if (x?.sections[i]) {
                          for (let j = 0; j < x?.sections?.[i]?.fields?.length; j++) {
                            if (x?.sections?.[i]?.fields[j]) {
                              for (let k = 0; k < x?.sections?.[i]?.fields[j]?.length; k++) {
                                if (x?.sections?.[i]?.fields[j][k]) {
                                  if (x.sections[i].fields[j][k].id == field?.id) {
                                    x.sections[i].fields[j][k].value = option;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    return before;
                  });
                }}
              />
            </Grid>
          ))}
        </Grid>
      </FullField>
    </Grid>
  );
};

export default DisplayUniqueChoice;
