import * as React from "react";
import { Grid, CircularProgress } from "@mui/material";
import makeAPIRequest from "../utils/makeAPIRequest";
import SimpleFullPage from "./SimpleFullPage";
import useQuery from "../utils/hooks/useQuery";
import {
  GenericLongText,
  GenericTextInput,
  useOpenSnackbar,
} from "./CreateOrEditChatbotLexPage";
import { GenericFromPopup, GenericSendButton } from "./ChatbotLexPage";
import { DepotFichierData } from "./EspaceDepotPage";
import { DownloadRounded, SupervisorAccountRounded } from "@mui/icons-material";
import { genericGetFromTimestamp } from "../components/AdminGroups/CreateGroupPopup";
import { GenericCard } from "./QuestionnairePage";
import ImportButton from "../components/Library/Buttons/ImportButton/ImportButton";

export const AdminDepotBox = (props: { noPadding?: boolean }) => {
  const idQuery = useQuery().get("id");
  const snackbarOpenUse = useOpenSnackbar();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [depot, setDepot] = React.useState<DepotFichierData>(undefined);
  const [someFileDownloading, setSomeFileDownloading] =
    React.useState<boolean>(false);
  const [revisionOpen, setRevisionOpen] = React.useState<boolean>(false);
  const [adminRevision, setAdminRevision] = React.useState<
    Partial<DepotFichierData>
  >({
    revisionComment: "",
    fileBase64: "",
    fileName: "",
  });
  const [depotLoading, setDepotLoading] = React.useState<boolean>(false);

  const initFile = () => {
    if (!idQuery) {
      return;
    }
    setIsLoading(true);
    makeAPIRequest("get", `/depot/${idQuery}/file`, null, "v3")
      .then((res) => {
        setDepot(res?.data);
      })
      .catch((err) => {
        snackbarOpenUse.error(
          "Impossible de récupérer ce fichier, il n'existe peut-être plus.",
          err
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    initFile();
  }, [idQuery]);

  return (
    <>
      {revisionOpen && (
        <GenericFromPopup
          onClose={() => setRevisionOpen(false)}
          title={{
            leftPart: "Réviser",
            rightPart: "le fichier",
          }}
          xs={6}
          addCloseSecurity={true}
        >
          <Grid container direction="column" padding="20px" gap="10px">
            <Grid item display="grid">
              <GenericLongText
                placeholder={"Vous pouvez laisser un commentaire ici."}
                fieldTitle="Commentaire"
                isMandatory={false}
                id="comment-depot-id"
                value={adminRevision?.revisionComment}
                maxLength={5000}
                onChange={(e) => {
                  setAdminRevision((x) => {
                    return {
                      ...x,
                      revisionComment: e ? e : "",
                    };
                  });
                }}
              />
            </Grid>

            <Grid item display="grid" paddingTop="10px" paddingBottom="10px">
              <ImportButton
                isAfterImport={adminRevision?.fileBase64 != ""}
                fileNameAfterImport={adminRevision?.fileName}
                onCloseAfterImport={() =>
                  setAdminRevision((x) => {
                    return {
                      ...x,
                      fileBase64: "",
                      fileName: "",
                    };
                  })
                }
                label={"Joindre un fichier"}
                onChange={(e) => {
                  const fileReader = new FileReader();
                  const file = e.target.files?.[0];

                  if (file) {
                    if (file.size >= 20 * 1024 * 1024) {
                      snackbarOpenUse.error(
                        "Le fichier est trop lourd, il ne doit pas dépasser 20MB."
                      );
                    } else {
                      fileReader.readAsDataURL(file);
                      fileReader.onload = (event) => {
                        const base64Data = event.target?.result as string;

                        setAdminRevision((prevData) => ({
                          ...prevData,
                          fileBase64: base64Data,
                          fileName: file.name,
                        }));
                      };
                    }
                  }
                }}
                acceptedFormat={[
                  ".xls",
                  ".xlsx",
                  ".csv",
                  ".txt",
                  ".docx",
                  ".doc",
                ]}
              >
                <></>
              </ImportButton>
            </Grid>
            <Grid item display="grid">
              <GenericSendButton
                errorMessages={{
                  noFile: "Aucun fichier n'a été déposé.",
                }}
                errorSelector={{
                  noFile: "hide",
                }}
                invalidConditions={{
                  noFile:
                    !adminRevision?.fileName || !adminRevision?.fileBase64,
                }}
                onSendClick={() => {
                  setDepotLoading(true);
                  makeAPIRequest(
                    "post",
                    `/depot/${depot?._id}/revision`,
                    adminRevision,
                    "v3"
                  )
                    .then((res) => {
                      snackbarOpenUse.success(
                        "Le fichier a bien été révisé.",
                        res
                      );
                      initFile();
                      setRevisionOpen(false);
                    })
                    .catch((err) => {
                      snackbarOpenUse.error(
                        "Impossible de réviser ce fichier.",
                        err
                      );
                    })
                    .finally(() => setDepotLoading(false));
                }}
                isLoading={depotLoading}
                textButton={"Valider"}
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      <Grid
        container
        wrap="nowrap"
        direction="column"
        paddingLeft={props.noPadding ? 0 : "5%"}
        paddingTop={props.noPadding ? 0 : "5%"}
        paddingRight={props.noPadding ? 0 : "5%"}
        paddingBottom={props.noPadding ? 0 : "5%"}
      >
        <Grid item display="grid">
          {isLoading && (
            <Grid item display="grid" paddingTop="20px">
              <Grid
                container
                direction="row"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            </Grid>
          )}
          {depot && !isLoading && (
            <>
              <GenericCard
                rightPin={
                  depot?.waitingForRevision
                    ? `En attente de révision`
                    : undefined
                }
                Header={
                  <Grid container direction="column" wrap="nowrap" gap="5px">
                    <Grid item display="grid">
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#0B243C",
                          fontFamily: "Poppins",
                        }}
                      >
                        {depot?.title ? depot.title : "Sans titre"}
                      </span>
                    </Grid>
                    {depot?.created && (
                      <Grid item display="grid">
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#0B243C",
                            fontFamily: "Poppins",
                          }}
                        >
                          {`Créé le ` +
                            genericGetFromTimestamp(
                              depot.created,
                              "dd/mm/yyyy à hh:min"
                            )}
                        </span>
                      </Grid>
                    )}
                    {depot?.lastUpdate && (
                      <Grid item display="grid">
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#0B243C",
                            fontFamily: "Poppins",
                          }}
                        >
                          {`Denière révision le ` +
                            genericGetFromTimestamp(
                              depot.lastUpdate,
                              "dd/mm/yyyy à hh:min"
                            )}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                }
                rightButtons={[
                  !depot?.waitingForRevision ? undefined : {
                    Icon: SupervisorAccountRounded,
                    disable: false,
                    title: "Effectuer la révision",
                    onClick: () => {
                      setRevisionOpen(true);
                    },
                  },
                  {
                    Icon: DownloadRounded,
                    disable: someFileDownloading,
                    title: "Télécharger le fichier",
                    onClick: () => {
                      setSomeFileDownloading(true);
                      if (depot?.fileBase64 && depot?.fileName) {
                        const base64Content = depot.fileBase64.split(",")[1];
                        const mimeType = depot.fileBase64
                          .split(",")[0]
                          .match(/:(.*?);/)[1];

                        const binaryString = atob(base64Content);
                        const binaryLen = binaryString.length;
                        const bytes = new Uint8Array(binaryLen);
                        for (let i = 0; i < binaryLen; i++) {
                          bytes[i] = binaryString.charCodeAt(i);
                        }

                        const fileBlob = new Blob([bytes], {
                          type: mimeType,
                        });

                        const downloadLink = document.createElement("a");
                        downloadLink.href = URL.createObjectURL(fileBlob);
                        downloadLink.download = depot.fileName;

                        downloadLink.click();

                        URL.revokeObjectURL(downloadLink.href);

                        snackbarOpenUse.success(
                          "Fichier téléchargé avec succès !"
                        );
                      } else {
                        snackbarOpenUse.error(
                          "Fichier non valide ou manquant."
                        );
                      }
                      setSomeFileDownloading(false);
                    },
                    color: "rgb(11, 36, 60)",
                  },
                  depot?.waitingForRevision ? undefined : undefined,
                ].filter((x) => x !== undefined)}
              >
                <>
                  <Grid container direction="column" wrap="nowrap" gap="5px">
                    <Grid item display="grid">
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#0B243C",
                          fontFamily: "Poppins",
                        }}
                      >
                        {depot?.fileName
                          ? `Nom du fichier : ` + depot.fileName
                          : "Nom de fichier non renseigné."}
                      </span>
                    </Grid>
                    {depot?.description && (
                      <Grid item display="grid">
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#0B243C",
                            fontFamily: "Poppins",
                          }}
                        >
                          {depot?.description}
                        </span>
                      </Grid>
                    )}
                    {depot?.revisionComment && (
                      <Grid item display="grid" paddingTop="20px">
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#0B243C",
                            fontFamily: "Poppins",
                            fontStyle: "italic",
                          }}
                        >
                          Commentaire : {depot?.revisionComment}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </>
              </GenericCard>
            </>
          )}
          {!depot && !isLoading && (
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                color: "#0B243C",
              }}
            >
              Ce fichier est introuvable.
            </span>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const AdminDepotPage: React.FC = () => {
  return (
    <>
      <SimpleFullPage>
        <Grid
          container
          wrap="nowrap"
          width="95vw"
          direction="column"
          maxWidth="700px"
        >
          <Grid item display="grid">
            <AdminDepotBox />
          </Grid>
        </Grid>
      </SimpleFullPage>
    </>
  );
};

export default AdminDepotPage;
